import { MessageCircle, Inbox, Phone, Puzzle, FileText } from "lucide-react";

const FeaturesList = () => {
  const features = [
    { icon: MessageCircle, text: "Chatbots y sesiones ilimitadas" },
    { icon: Inbox, text: "Inbox con agentes ilimitados" },
    { icon: Phone, text: "1,000 conversaciones de WhatsApp iniciadas por el usuario GRATIS" },
    { icon: Phone, text: "Números de WhatsApp ilimitados" },
    { icon: Puzzle, text: "Todas las extensiones e integraciones incluidas" },
    { icon: FileText, text: "111 plantillas de chatbot gratuitas" },
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-2xl font-light text-center mb-4">
        Todos nuestros <span style={{ fontWeight: 'bold' }}>planes de pago</span> incluyen
      </h2>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-6">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-col items-center">
            <feature.icon className="h-8 w-8 text-green-500" />
            <span className="text-sm text-center">{feature.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesList;
