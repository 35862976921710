import { CircleUserRound, BotMessageSquare, ShoppingBag, MessageCircleHeart } from 'lucide-react';

const IconSection4 = () => (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <div className="flex flex-col items-center text-center">
            <MessageCircleHeart className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">WhatsApp + ChatGPT para marketing y ventas</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <BotMessageSquare className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">WhatsApp + ChatGPT para la atención al cliente</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <ShoppingBag className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">WhatsApp + ChatGPT para eCommerce</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <CircleUserRound className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Campañas conversacionales con promociones</h4>
          </div>
        </div>
      </div>
    </section>
  );

export default IconSection4;