import React from 'react';
import PartnerEarnings from '../componentes/PartnerEarnings';
import PartnerTypes from '../componentes/partnerTypes'
import Component from '../componentes/Component';
import PartnerNetworkSteps from '../componentes/PartnerNetworkSteps';
import PartnersCheck from '../componentes/PartnersCheck';
import ContactPartner from '../componentes/ContactPartner'
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

export default function PartnerSection() {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
              Conviértete en partner de Bot de WhatsApp y gana el 10% de comisiones de tus afiliados mensualmente
            </h2>
            <p className="text-lg font-light text-gray-700 mb-6">Te ofrecemos:</p>
            <ul className="list-disc list-inside text-gray-700 mb-8 space-y-2">
              <li>Comisión por cada licencia de tus clientes.</li>
              <li>Diferentes tipos de partners para adaptarnos a tu negocio.</li>
              <li>Plantillas de chatbots listas para utilizar en tus clientes.</li>
              <li>Acceso a campañas conjuntas.</li>
              <li>Soporte de nuestro equipo 24 horas.</li>
            </ul>
            <div className="space-x-4">
              <a
                href="https://wa.me/573161392317?text=partners" // Reemplaza con la URL deseada para "Conviértete en partner"
                target="_blank"
                rel="noopener noreferrer"
                className="border border-green-500 text-green-500 px-6 py-3 rounded-lg"
              >
                Conviértete en partner
              </a>
              <a
                href="https://wa.me/573161392317?text=partners" // Reemplaza con la URL deseada para "WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition duration-300"
              >
                WhatsApp
              </a>
            </div>

          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/15.png" // Cambia esto a tu URL o ruta deseada
              alt="Partner con Bot de WhatsApp"
              width={500}
              height={400}
              className="rounded-lg" // Se eliminó la clase shadow-lg
            />
          </div>
        </div>
      </div>
      {/* Componente con fondo gris que ocupa toda la pantalla */}
      <div className="bg-gray-100 min-h-screen">
        <PartnerEarnings />
        <PartnerTypes />
        <Component />
        <PartnerNetworkSteps/>
        <PartnersCheck />
        <ContactPartner />
        <WhatsAppChatbot />
      </div>
    </div>
  );
}
