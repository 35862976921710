import { useState, useEffect } from 'react';

interface HeroSectionProps {
  heroTexts: string[];
  typingSpeed: number; // Este prop se puede eliminar si solo se usará el cambio de texto
}

const HeroSection = ({ heroTexts }: HeroSectionProps) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState(heroTexts[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % heroTexts.length);
    }, 2000); // Cambia cada 2 segundos

    return () => clearInterval(interval);
  }, [heroTexts]);

  useEffect(() => {
    setDisplayText(heroTexts[currentTextIndex]);
  }, [currentTextIndex, heroTexts]);

  return (
    <section className="container mx-auto px-6 py-16 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 lg:pr-24 md:pr-16 flex flex-col items-start">
        <h1 className="text-4xl font-bold mb-4">
          Soluciones de WhatsApp para{' '}
          <span className="text-green-500 font-extrabold min-h-[40px] inline-block">
            {displayText}
          </span>
        </h1>
        <p className="mb-8 text-xl">
          <span className="font-light">Con</span> <strong>Bot de WhatsApp</strong>{' '}
          <span className="font-light">convierte tus</span> conversaciones de WhatsApp en ventas{' '}
          <span className="font-light">y mejora la experiencia de tus clientes.</span>
        </p>
        <div className="flex space-x-4 mb-8">
          <a href="https://wa.me/573161392317?text=hola" target="_blank" rel="noopener noreferrer">
            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Habla con nosotros
            </button>
          </a>
          <a href="https://wa.me/573161392317?text=hola" target="_blank" rel="noopener noreferrer">
            <button className="border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-blue-100">
              Pruébalo ahora
            </button>
          </a>
        </div>
        <img 
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/meta-whatsapp-TbjDOIaNUsk1yJeldwrqMUNetyGU20.png" 
          alt="Meta WhatsApp" 
          width={300} 
          height={50} 
          className="mb-2"
        />
        <p className="text-sm text-gray-600 mb-8">
          Socio Comercial de <strong className="font-bold">Meta</strong> y Proveedor de Soluciones Empresariales de{' '}
          <strong className="font-bold">WhatsApp</strong>
        </p>
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0">
        <img 
          src="./100.png" 
          alt="Woztell Conversational Solutions" 
          width={600} 
          height={400} 
          className="w-full h-auto"
        />
      </div>
    </section>
  );
};

export default HeroSection;
