import React from 'react';
import ContactForm from '../componentes/ContactForm';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import HeroRecursos from '../componentes/HeroRecursos';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const DatabaseOverview: React.FC = () => {
  return (
    <>
      <HeroRecursos />
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Base de Datos en WhatsApp Bot</h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img
                src="/basededatos.jpg" // Asegúrate de que la ruta sea correcta
                alt="WhatsApp Database Bot"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
            <div className="md:w-1/2 md:pl-8">
              <p className="text-lg mb-4">
                Nuestro bot de WhatsApp está equipado con una potente funcionalidad de base de datos que almacena automáticamente los contactos de todos los usuarios que interactúan con él.
              </p>
              <p className="text-lg mb-4">
                Esta característica permite una gestión eficiente de los contactos y facilita el acceso a la información del usuario directamente desde la interfaz de WhatsApp.
              </p>
              <p className="text-lg">
                Con un simple comando, puedes ver y gestionar todos los contactos almacenados, lo que hace que la administración de tu base de datos sea rápida y sencilla.
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </>
  );
};

export default DatabaseOverview;
