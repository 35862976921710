import React, { useState, useEffect } from 'react';
import ClientsSection from '../componentes/ClientsSection';
import ComponentSlide from '../componentes/ComponentSlide';
import WhatsAppChatGPTComponent from '../componentes/WhatsAppChatGPTComponent';
import Youtube from '../componentes/youtube';
import Slidetext from '../componentes/Slidetext';
import Funcionalidades from '../componentes/Funcionalidades';
import Preguntas from '../componentes/Preguntas';
import WhyTrustUsSection from '../componentes/WhyTrustUsSection';
import ContactForm from '../componentes/ContactForm';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const changingTexts = [
  "INFORMACIÓN SOBRE TUS CLIENTES Y LA CONVERSACIÓN",
  "SUGERENCIAS DE RESPUESTAS HIPER PERSONALIZADAS",
  "COMPORTAMIENTO PREDEFINIDO",
  "EL CONTEXTO DE TU EMPRESA",
  "INFORMACIÓN SOBRE TUS CLIENTES Y LA CONVERSACIÓN"
];

const WhatsAppChatGPTIntegration: React.FC = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % changingTexts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-start justify-between">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h2 className="text-3xl font-bold mb-4">
                Crea un chatbot conectado a WhatsApp y ChatGPT que trabaje por ti 24/7
              </h2>
              <h3 className="text-xl font-semibold mb-8">
                Conversaciones inteligentes con WhatsApp y ChatGPT
              </h3>
              <p className="text-lg mb-6">
                Combina la eficacia de WhatsApp API y la inteligencia de ChatGPT para vender más y reducir costes.
              </p>
              <p className="text-lg font-light mb-6">
                Crea chatbots conectados a tus propios GPTs con:
              </p>
              <div className="h-16 flex items-center mb-8">
                <p className="text-3xl font-extrabold mb-4 text-green-500 transition-all duration-300 ease-in-out">
                  {changingTexts[currentTextIndex]}
                </p>
              </div>
              <div className="flex space-x-4 mb-8">
                <a href="https://wa.me/573161392317?text=integraciones" className="bg-green-500 text-white border-2 border-green-600 px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                  Habla con nosotros
                </a>
                <a href="https://wa.me/573161392317?text=integraciones" className="border-2 border-green-500 text-green-500 px-4 py-2 rounded hover:bg-blue-100 transition duration-300">
                  Pruébalo Ahora
                </a>
              </div>
              <img 
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/meta-whatsapp-TbjDOIaNUsk1yJeldwrqMUNetyGU20.png" 
                alt="Meta WhatsApp" 
                width={300} 
                height={50} 
                className="mb-2"
              />
              <p className="text-sm text-gray-600 mb-8">
                Socio Comercial de <strong className="font-bold">Meta</strong> y Proveedor de Soluciones Empresariales de{' '}
                <strong className="font-bold">WhatsApp</strong>
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8">
              <img
                src="./104.png"
                alt="WhatsApp ChatGPT Integration"
                width={500}
                height={300}
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>
      

      {/* Aquí se incluye el componente ClientsSection */}
      <ClientsSection />
      <ComponentSlide />
      <WhatsAppChatGPTComponent />
      <Youtube />
      <Slidetext />
      <Funcionalidades />
      <Preguntas />
      <WhyTrustUsSection />
      <ContactForm />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </>
  );
};

export default WhatsAppChatGPTIntegration;
