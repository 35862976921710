import React from 'react';
import { CheckCircle, Circle } from 'lucide-react';

interface Option {
  title: string;
  items: string[];
}

interface Category {
  category: string;
  options: Option[];
}

interface Step {
  title: string;
  content: Category[] | null; // Asegúrate de que solo acepte categorías o null
}

const steps: Step[] = [
  {
    title: "Definimos los objetivos de tu chatbot en WhatsApp en base a las necesidades de tu empresa",
    content: null
  },
  {
    title: "En base a tus objetivos, desarrollamos el chatbot ideal",
    content: [
      {
        category: "Experiencia de usuario",
        options: [
          { title: "Nivel de inmersión de la experiencia:", items: ["Chat", "Objetos de WhatsApp", "Voz", "Avatares"] }
        ]
      },
      // Otras categorías aquí...
    ]
  },
  {
    title: "Metodología de trabajo: te acompañamos en todo el proceso hasta el lanzamiento",
    content: [
      {
        category: "Documentación",
        options: [
          { title: "Documentos disponibles:", items: ["Diagrama funcional", "Diagrama de Flujo", "Objetos de WhatsApp", "Integraciones", "Contenidos"] }
        ]
      }
    ]
  },
  {
    title: "Seguimiento y optimización: Te ayudamos a mejorar el chatbot y nos aseguramos que cumpla tus objetivos",
    content: [
      {
        category: "Optimización",
        options: [
          { title: "Servicios de seguimiento:", items: ["Análisis de la experiencia de usuario", "Generación de audiencias en base a la interacción", "Monitorización de KPIs", "Mejoras iterativas"] }
        ]
      }
    ]
  }
];

const WhatsAppSalesSuccess: React.FC = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-12">Nuestro éxito es que <span style={{ fontWeight: 'bold' }}>vendas más con WhatsApp</span></h2>
        <h3 className="text-2xl font-light text-center mb-8">Te contamos cómo lo hacemos</h3>
        
        <div className="space-y-12">
          {steps.map((step, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start mb-4">
                <div className="bg-blue-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4 flex-shrink-0">
                  {index + 1}
                </div>
                <h4 className="text-xl font-semibold">{step.title}</h4>
              </div>

              {/* Manejo del contenido */}
              {step.content && Array.isArray(step.content) ? (
                <div className="ml-12 space-y-4">
                  {step.content.map((category, categoryIndex) => (
                    <div key={categoryIndex}>
                      <h5 className="font-semibold mb-2">{category.category}</h5>
                      {category.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="mb-4">
                          <p className="font-medium mb-2">{option.title}</p>
                          <ul className="space-y-1">
                            {option.items.map((item, itemIndex) => (
                              <li key={itemIndex} className="flex items-center">
                                <Circle className="w-4 h-4 mr-2 text-blue-600" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatsAppSalesSuccess;
