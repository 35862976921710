import { Check } from "lucide-react";

const FeatureCheck = () => {
  const features = [
    "Crear chatbots para WhatsApp y Web",
    "Gestionar Contactos",
    "Enviar mensajes masivos GRATIS para WhatsApp",
    "Plataforma de envío de mensajes masivos de WhatsApp",
    "Integración de catálogo de WhatsApp y WhatsApp E-Commerce",
    "Integración con ChatGPT",
    "Secuencias de WhatsApp, para campañas de ADS",
    "Asignación automática de chat",
    "Recibe pagos en tu WhatsApp a través de PayPal, Stripe y más.",
    "Creación de plantillas",
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-2xl font-light text-center mb-6">
        Y además podrás aprovechar nuestras <span style={{ fontWeight: 'bold' }}>funcionalidades</span>
      </h2>
      <div className="bg-gray-100 rounded-lg p-4 shadow-md">
        <ul className="grid gap-4 sm:grid-cols-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start space-x-2">
              <Check className="h-6 w-6 text-green-500 flex-shrink-0 mt-0.5" />
              <span className="text-sm">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FeatureCheck;
