import React from 'react';

const cards = [
  {
    id: 1,
    title: "WhatsApp con Base de Datos",
    description: "Nuestro bot de WhatsApp está equipado con una potente funcionalidad de base de datos que almacena..",
    image: "/base.jpg", // Cambia esta ruta a tu imagen
    link: "/whatsapp-con-base-de-datos"
  },
  {
    id: 2,
    title: "Programas Mensajes en WhatsApp",
    description: "Optimiza tus estrategias de ventas con nuestra función de programación de mensajes en WhatsApp..",
    image: "/programar.jpg", // Cambia esta ruta a tu imagen
    link: "/programar-mensajes-en-whatsapp"
  },
  {
    id: 3,
    title: "Agendar Citas en WhatsApp",
    description: "Optimiza tu gestión de citas con nuestro bot de WhatsApp. Permite a tus clientes agendar citas fácilmente..",
    image: "/1003.jpg", // Cambia esta ruta a tu imagen
    link: "/agendar-citas-en-whatsapp"
  }
];

const CardSection: React.FC = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Nuestras Soluciones</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <a href={card.link} key={card.id} className="block">
              <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
                <div className="relative h-48">
                  <img
                    src={card.image} // Asegúrate de que la ruta sea correcta
                    alt={card.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
                  <p className="text-gray-600">{card.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardSection;
