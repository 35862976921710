import '../App.css';

const WhatsAppCloudAPISection = () => {
    // Definir un array de URLs de las imágenes
    const features = [
      { image: './10.png', description: 'Botones Interactivos' },
      { image: './11.png', description: 'Listas' },
      { image: './12.png', description: 'Productos' },
      { image: './13.png', description: 'WhatsApp Flows' },
      { image: './14.png', description: 'Carrusel' },
    ];
  
    return (
      <section className="py-16 " >
        <div className="container mx-auto px-6">
          <h2 className="text-2xl font-bold text-center mb-8">
          <span className="font-light">Con Bot de WhatsApp tendrás acceso a todas sus</span> funcionalidades exclusivas <span className="font-light">de WhatsApp Cloud API</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 ">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center ">
                <img src={feature.image} alt={`WhatsApp Benefit ${index + 1}`} width={200} height={200} />
                <p className="text-center">{feature.description}</p>
              </div>
            ))}
          </div>
          <div className='mt-8 pt-8 border-t border-green-700 text-center text-sm'></div>
        </div>
      </section>
    );
  };

export default WhatsAppCloudAPISection;