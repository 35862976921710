import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const WhyTrustUsSection = () => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [currentNumbers, setCurrentNumbers] = useState([0, 0, 0]); // Estado para los números actuales

  useEffect(() => {
    setHasAnimated(true);

    // Función para contar hasta 7
    const countToSeven = (index: number, maxNumber: number) => {
      let currentCount = 0;
      const interval = setInterval(() => {
        if (currentCount < maxNumber) {
          currentCount++;
          setCurrentNumbers((prev) => {
            const newNumbers = [...prev];
            newNumbers[index] = currentCount;
            return newNumbers;
          });
        } else {
          clearInterval(interval);
        }
      }, 100); // Cambiar a 100ms para un conteo más rápido
    };

    // Iniciar el conteo para cada número
    [10, 10, 7].forEach((maxNumber, index) => {
      countToSeven(index, maxNumber);
    });
  }, []);

  return (
    <section className="py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-16"><span className="font-light">¿Por qué</span> confiar <span className="font-light">en nosotros?</span></h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { maxNumber: 10, suffix: " M", text: "mensajes procesados" },
            { maxNumber: 50, suffix: " k", text: "usuarios registrados" },
            { maxNumber: 7, suffix: "", text: "países cuidando de nuestros clientes" }
          ].map((item, index) => (
            <div key={index} className="text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ scale: 1.1 }} // Aumenta ligeramente al pasar el mouse
                whileTap={{ scale: 0.95 }} // Se reduce un poco al hacer clic
              >
                <motion.span 
                  className="text-5xl font-bold text-green-500"
                >
                  {currentNumbers[index]} {/* Mostrar el número actual */}
                </motion.span>
                <span className="text-5xl font-bold text-green-500">{item.suffix}</span>
                <p className="text-xl mt-2">{item.text}</p>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyTrustUsSection;
