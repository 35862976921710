"use client";

import React, { useState } from 'react';
import { CheckIcon, XIcon } from 'lucide-react';

// Define las características
const features = [
  { name: "Miembros", free: "5.000", starter: "50.000", growth: "ilimitado" },
  { name: "Base de Datos", free: "5.000", starter: "50.000", growth: "ilimitado" },
  { name: "Usuarios Activos", free: "Free", starter: "Free", growth: "Free" },
  { name: "ChatBot", free: "8", starter: "20", growth: "60" },
  { name: "Recargo sobre WhatsApp", free: "0%", starter: "0%", growth: "0%" },
  { name: "WhatsApp Business Mensajes", free: "Ilimitado", starter: "Ilimitado", growth: "Ilimitado" },
  { name: "Web", free: "Ilimitado", starter: "Ilimitado", growth: "Ilimitado" },
  { name: "Mensajes masivos", free: true, starter: true, growth: true },
  { name: "Etiquetado de audiencias", free: true, starter: true, growth: true },
  { name: "Aplicaciones de chat", free: true, starter: true, growth: true },
  { name: "Creador de Chatbots", free: true, starter: true, growth: true },
  { name: "Chatbot de palabras clave", free: true, starter: true, growth: true },
  { name: "Chatbot combinacional", free: true, starter: true, growth: true },
  { name: "Chatbot Guiado", free: true, starter: true, growth: true },
  { name: "Chatbot con AI", free: true, starter: true, growth: true },
  { name: "Integraciones", free: true, starter: true, growth: true },
  { name: "Soporte", free: "+10", starter: "+10", growth: "+10" },
  { name: "Plantillas de Chatbots listas para usar", free: "4", starter: "4", growth: "4" },
  { name: "Onboarding diarios y videos", free: "-", starter: "-", growth: "1h" },
  { name: "Onboarding en directo 1 a 1", free: "Sí", starter: "Sí", growth: "Sí" },
  { name: "Soporte por Email", free: "-", starter: "-", growth: "Sí" },
  { name: "Asistencia remota", free: "Transferencia de conocimientos", starter: "Transferencia de conocimientos", growth: "Transferencia de conocimientos" },
  { name: "Plantilla de chatbot lista para usar", free: true, starter: true, growth: true },
  { name: "Base de conocimientos", free: true, starter: true, growth: true },
  { name: "Foros de la Comunidad", free: true, starter: true, growth: true },
  { name: "Portal del cliente", free: true, starter: true, growth: true },
  { name: "Curso en Vídeo", free: true, starter: true, growth: true },
  { name: "", free: undefined, starter: undefined, growth: undefined }, // Manejo de caso vacío
];

// Componente principal
const FeatureComparison = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="text-center">
      <span
        className="text-green-500 font-bold text-lg cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        Ver comparativa de funcionalidades
      </span>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-4xl max-h-[80vh] overflow-y-auto relative">
            <button
              className="absolute top-4 right-4 text-red-500"
              onClick={() => setIsOpen(false)}
            >
              <XIcon />
            </button>
            <h2 className="text-2xl font-bold mb-4">Comparativa de funcionalidades</h2>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-2 text-left">Característica</th>
                  <th className="border p-2 text-left">Basico</th>
                  <th className="border p-2 text-left">Estandar</th>
                  <th className="border p-2 text-left">Premium</th>
                </tr>
              </thead>
              <tbody>
                {features.map((feature, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="border p-2">{feature.name}</td>
                    <td className="border p-2">
                      {renderValue(feature.free)}
                      {index === features.length - 1 && (
                        <div className="mt-2">
                          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                            Empieza Ahora
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="border p-2">
                      {renderValue(feature.starter)}
                      {index === features.length - 1 && (
                        <div className="mt-2">
                          <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                            Reserva Demo
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="border p-2">
                      {renderValue(feature.growth)}
                      {index === features.length - 1 && (
                        <div className="mt-2">
                          <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                            Reserva Demo
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

// Función para renderizar los valores
function renderValue(value: string | boolean | undefined) {
  if (typeof value === 'boolean') {
    return value ? <CheckIcon className="text-green-500" /> : <XIcon className="text-red-500" />;
  }
  return value !== undefined ? value : '-'; // Manejo del caso undefined
}

// Exporta el componente
export default FeatureComparison;
