import React from 'react';
import HeroRecursos from '../componentes/HeroRecursos'; // Asegúrate de importar HeroRecursos
import { Calendar, Database, Eye, MessageSquare } from 'lucide-react';
import ContactForm from '../componentes/ContactForm';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const AppointmentSchedulerSection: React.FC = () => {
  return (
    <>
      <HeroRecursos />
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 mb-8 lg:mb-0">
              <img
                src="/1002.jpg" // Asegúrate de que la ruta sea correcta
                alt="Agendamiento de Citas en WhatsApp"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
            <div className="lg:w-1/2 lg:pl-12">
              <h2 className="text-3xl font-bold mb-6">Agendamiento de Citas con WhatsApp Bot</h2>
              <p className="text-lg mb-6">
                Optimiza tu gestión de citas con nuestro bot de WhatsApp. Permite a tus clientes agendar citas fácilmente, 
                mientras tú mantienes un control total sobre tu calendario.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="flex items-start">
                  <Calendar className="w-8 h-8 text-blue-500 mr-3 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Agendamiento Sencillo</h3>
                    <p>Los clientes pueden agendar citas directamente a través del chat de WhatsApp.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Database className="w-8 h-8 text-green-500 mr-3 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Almacenamiento Seguro</h3>
                    <p>Todas las citas se guardan automáticamente en una base de datos segura.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Eye className="w-8 h-8 text-purple-500 mr-3 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Visualización Fácil</h3>
                    <p>Accede a todas las citas del día con un simple comando desde cualquier WhatsApp.</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <MessageSquare className="w-8 h-8 text-yellow-500 mr-3 mt-1" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Recordatorios Automáticos</h3>
                    <p>El bot envía recordatorios automáticos a los clientes antes de su cita.</p>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Ver Citas del Día:</h3>
                <code className="text-lg font-mono bg-gray-100 p-2 rounded block">
                  /vercitas
                </code>
                <p className="mt-2 text-sm text-gray-600">
                  Envía este comando desde cualquier WhatsApp autorizado para ver todas las citas programadas para hoy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
        <ConversationalProjectSection />
        <WhatsAppChatbot />
      </section>
    </>
  );
};

export default AppointmentSchedulerSection;
