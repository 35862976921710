import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PromoBar from './componentes/PromoBar';
import HeroSection from './componentes/HeroSection';
import Footer from './componentes/footer';
import Navigation from './componentes/menu';
import ClientsSection from './componentes/ClientsSection';
import WhyTrustUsSection from './componentes/WhyTrustUsSection';
import ProjectsSection from './componentes/ProjectsSection';
import WhatsAppBenefitsSection from './componentes/WhatsAppBenefitsSection';
import IconSection from './componentes/IconSection';
import CustomerServiceSection from './componentes/CustomerServiceSection';
import IconSection2 from './componentes/IconSection2';
import ConversationalCommerceSection from './componentes/ConversationalCommerceSection';
import IconSection3 from './componentes/IconSection3';
import ImageGallerySection from './componentes/ImageGallerySection';
import IconSection4 from './componentes/IconSection4';
import Chatgpt from './componentes/Chatgpt';
import WhatsAppCloudAPISection from './componentes/WhatsAppCloudAPISection';
import ConversationalProjectSection from './componentes/ConversationalProjectSection';
import CSRSection from './componentes/CSRSection';
import Soluciones from './page/soluciones'; // Importar la página Soluciones
import Planes from './page/planes';
import PartnerSection from './page/partners';
import HeroChatbots from './page/chatbots';
import WhatsAppChatGPTIntegration from './page/integraciones';
import Funcionalidades from './page/funcionalidades';
import Recursos from './page/recursos'
import DatabaseOverview from './page/whatsapp-con-base-de-datos'
import ScheduledMessagesSection from './page/programar-mensajes-en-whatsapp'
import AppointmentSchedulerSection from './page/agendar-citas-en-whatsapp'
import WhatsAppChatbot from './componentes/WhatsAppChatbot';
import Subirimagen from './page/subirimagen';

const App = () => {
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutos en segundos
  const [showPromo, setShowPromo] = useState(true);

// Formatear el tiempo
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

  // Actualizar el contador cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const heroTexts = ['SOPORTE', 'ECOMMERCE', 'MARKETING Y VENTAS'];
  const typingSpeed = 3000; // Cambia de texto cada 3 segundos

  return (
    <Router>
      <div>
        {/* Mostrar la barra de oferta mientras la promoción esté activa */}
        {showPromo && (
          <PromoBar
            timeLeft={timeLeft}
            formatTime={formatTime}
            onClose={() => setShowPromo(false)}
          />
        )}

        <Navigation />
        
        {/* Rutas de la aplicación */}
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection heroTexts={heroTexts} typingSpeed={typingSpeed} />
              <ClientsSection />
              <WhatsAppBenefitsSection />
              <IconSection />
              <CustomerServiceSection />
              <IconSection2 />
              <ConversationalCommerceSection />
              <IconSection3 />
              <Chatgpt />
              <IconSection4 />
              <WhatsAppCloudAPISection />
              <ImageGallerySection />
              <WhyTrustUsSection />
              <ProjectsSection />
              <ConversationalProjectSection />
              <CSRSection />
              <WhatsAppChatbot />
            </>
          } />
          <Route path="/soluciones" element={<Soluciones />} />
          <Route path="/planes" element={<Planes />} />
          <Route path="/partners" element={<PartnerSection />} />
          <Route path="/chatbots" element={<HeroChatbots />} />
          <Route path="/integraciones" element={<WhatsAppChatGPTIntegration />} />
          <Route path="/funcionalidades" element={<Funcionalidades />} />
          <Route path="/recursos" element={<Recursos />} />
          <Route path="/whatsapp-con-base-de-datos" element={<DatabaseOverview />} />
          <Route path="/programar-mensajes-en-whatsapp" element={<ScheduledMessagesSection />} />
          <Route path="/agendar-citas-en-whatsapp" element={<AppointmentSchedulerSection />} />
          <Route path="/subirimagen" element={<Subirimagen />} />

          {/* Puedes añadir más rutas aquí */}
        </Routes>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
