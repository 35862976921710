import { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import '../App.css';

const ProjectsSection = () => {
    const [showAllProjects, setShowAllProjects] = useState(false);
  
    useEffect(() => {
      console.log("Estado de showAllProjects:", showAllProjects);
    }, [showAllProjects]);
  
    const projects = [
      { title: "Simon Bolivar Art Academy", logo: "/504.png?height=50&width=50&text=Logo1", description: "-60% en costes de atención al alumno" },
      { title: "Guimo's Mexican Restaurant", logo: "./500.png?height=50&width=50&text=Logo2", description: "82% de recurrencia en consultas gracias a un chatbot en WhatsApp" },
      { title: "Bless This Burguer", logo: "./501.png?height=50&width=50&text=Logo3", description: "276% más usuarios gracias a WhatsApp" },
      { title: "Italian Pizza", logo: "/502.png?height=50&width=50&text=Logo4", description: "+57% nuevos clientes gracias a WhatsApp" },
      { title: "BarberShop", logo: "/503.png?height=50&width=50&text=Logo5", description: "13x más ingresos utilizando mensajería a traves de WhatsApp" },
      { title: "Authentic Vintage Appareal", logo: "/505.png?height=50&width=50&text=Logo6", description: "30% ahorro en costes utilizando WhatsApp para enviar mensajes" },
      { title: "Din Exchange", logo: "/506.png?height=50&width=50&text=Logo7", description: "Bot con precio del dolar actualizado para los clientes" },
      { title: "Dental Care", logo: "/507.png?height=50&width=50&text=Logo8", description: "Agendamiento de citas a los clientes" },
      { title: "Eyelash Extention", logo: "/508.png?height=50&width=50&text=Logo9", description: "Envio de ofertas y aumento de clientes" },
      { title: "Super Market", logo: "/509.png?height=50&width=50&text=Logo10", description: "Aumneto de las ventas online en 45%" },
      { title: "Dope Foot", logo: "/510.png?height=50&width=50&text=Logo11", description: "Muestra su catalogo online a todos los clientes" },
      { title: "Bucaramarketing", logo: "/511.png?height=50&width=50&text=Logo12", description: "90% de sus ventas aumentaron" },
    ];
  
    return (
      <section className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8"><span className="font-light">Algunos de nuestros</span> proyectos</h2>
          <div className="flex justify-center mb-8">
            <h6>Destacadas por: </h6>
            <img src="./meta.png?height=300&width=500&text=WhatsApp+Benefits" alt="WhatsApp Benefits" width={100} height={50} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {projects.slice(0, showAllProjects ? projects.length : 3).map((project, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <h3 className="text-xl font-semibold mb-4">{project.title}</h3>
                <div className="project-image-container">
                  <img src={project.logo} alt={project.title} className="project-image" />
                </div>
                <p>{project.description}</p>
              </div>
            ))}
          </div>
          {!showAllProjects && (
            <div className="flex justify-center mt-8">
              <button 
                onClick={() => setShowAllProjects(true)} 
                className="flex items-center bg-green-500 text-white py-2 px-4 rounded"
              >
                Ver más proyectos
                <ChevronDown className="ml-2" />
              </button>
            </div>
          )}
        </div>
      </section>
    );
  };

export default ProjectsSection;
