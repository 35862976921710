import React, { useState } from 'react';

const plans = [
  { name: 'Basico', price: 12 },
  { name: 'Estandar', price: 30 },
  { name: 'Premium', price: 80 }
];

const countries = ['Chile', 'Argentina', 'Mexico', 'Colombia', 'Peru', 'Venezuela', 'España', 'Uruguay', 'EEUU', 'Bolivia', 'Ecuador', 'Panama', 'El Salvador', 'Paraguay'];

const messageTypes = [
  { name: 'Marketing', pricePerMessage: 0 },
  { name: 'Utilidad', pricePerMessage: 0 },
  { name: 'Autentificación', pricePerMessage: 0 }
];

type MessageCount = {
  Marketing: number;
  Utilidad: number;
  Autentificación: number;
};

export default function PlanCalculator() {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [messageCount, setMessageCount] = useState<MessageCount>({
    Marketing: 4742,
    Utilidad: 1607,
    Autentificación: 4048
  });
  const [clientConversations, setClientConversations] = useState(10000);
  const [costBreakdown, setCostBreakdown] = useState<any>(null);
  const [recommendation, setRecommendation] = useState('');

  const calculateCost = () => {
    const licenseCost = selectedPlan.price;
    const marketingCost = messageCount.Marketing * messageTypes[0].pricePerMessage;
    const utilityCost = messageCount.Utilidad * messageTypes[1].pricePerMessage;
    const authCost = messageCount.Autentificación * messageTypes[2].pricePerMessage;
    const serviceCost = (clientConversations - 1000) * 0; // Asumiendo que los primeros 1000 son gratis
    const totalCost = licenseCost + marketingCost + utilityCost + authCost + serviceCost;

    setCostBreakdown({
      licenseCost,
      marketingCost,
      utilityCost,
      authCost,
      serviceCost,
      totalCost
    });

    // Calcular ahorros potenciales con el plan Agency
    const agencyTotalCost = plans[2].price + marketingCost + utilityCost + authCost + serviceCost;
    const savings = totalCost - agencyTotalCost;

    if (selectedPlan.name !== 'Agency' && savings > 0) {
      setRecommendation(`Cambiando a la licencia Agency, ahorrarías $${savings.toFixed(2)}.`);
    } else {
      setRecommendation('');
    }
  };

  const handleMessageCountChange = (type: keyof MessageCount, value: string) => {
    setMessageCount(prev => ({ ...prev, [type]: parseInt(value) || 0 }));
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-light mb-4">Calcula el <span style={{ fontWeight: 'bold' }}>plan</span> que mejor se adapta a ti</h2>
      
      <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-1/2">
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Escoge el plan</h3>
            <div className="flex space-x-4">
              {plans.map(plan => (
                <button
                  key={plan.name}
                  className={`px-4 py-2 rounded ${selectedPlan.name === plan.name ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                  onClick={() => setSelectedPlan(plan)}
                >
                  {plan.name} - ${plan.price}/mes
                </button>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">¿A qué país mandarás más WhatsApps?</h3>
            <select
              className="w-full p-2 border rounded"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              {countries.map(country => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Conversaciones iniciadas por la empresa al mes</h3>
            {messageTypes.map(type => (
              <div key={type.name} className="flex items-center mb-2">
                <label className="w-1/2">Cuántos mensajes de {type.name}?:</label>
                <input
                  type="number"
                  className="w-1/2 p-2 border rounded"
                  value={messageCount[type.name as keyof MessageCount]}
                  onChange={(e) => handleMessageCountChange(type.name as keyof MessageCount, e.target.value)}
                />
              </div>
            ))}
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Conversaciones iniciadas por el cliente al mes</h3>
            <div className="flex items-center">
              <label className="w-1/2">Cuántas conversaciones tendrás?:</label>
              <input
                type="number"
                className="w-1/2 p-2 border rounded"
                value={clientConversations}
                onChange={(e) => setClientConversations(parseInt(e.target.value) || 0)}
              />
            </div>
          </div>

          <div className="mt-6">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={calculateCost}
            >
              Calcular coste
            </button>
          </div>
        </div>

        <div className="w-full md:w-1/2">
          {costBreakdown && (
            <div className="bg-gray-100 p-4 rounded">
              <h3 className="text-xl font-bold mb-2">Desglose de costes:</h3>
              <p>Coste de la licencia: ${costBreakdown.licenseCost}</p>
              <p>Coste de Conversaciones de Marketing: ${costBreakdown.marketingCost.toFixed(2)} ({messageCount.Marketing} a ${messageTypes[0].pricePerMessage})</p>
              <p>Coste de Conversaciones de Utilidad: ${costBreakdown.utilityCost.toFixed(2)} ({messageCount.Utilidad} a ${messageTypes[1].pricePerMessage})</p>
              <p>Coste de Conversaciones de Autentificación: ${costBreakdown.authCost.toFixed(2)} ({messageCount.Autentificación} a ${messageTypes[2].pricePerMessage})</p>
              <p>Coste de Conversaciones de Service (+1000): ${costBreakdown.serviceCost.toFixed(2)} ({clientConversations - 1000} a $0.054)</p>
              <p className="font-bold mt-2">Coste Total: ${costBreakdown.totalCost.toFixed(2)}</p>

              {recommendation && (
                <div className="mt-4 bg-yellow-100 p-4 rounded">
                  <p className="font-bold">Recomendación: {recommendation}</p>
                </div>
              )}

              <div className="mt-4">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Empieza ahora
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
