import Button from './Button'; // Importa el componente Button

const ConversationalProjectSection = () => (
  <section className="py-16 bg-gray-50">
    <div className="container mx-auto px-6">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-3/4 pr-8">
          <h2 className="text-3xl font-light mb-6">Haz de tu proyecto conversacional una realidad con <span style={{ fontWeight: 'bold' }}>Bot de WhatsApp</span></h2>
          <p className="text-xl mb-8 font-light">
            Nuestro equipo de expertos está preparado para desarrollar e implementar soluciones conversacionales personalizadas a las necesidades de tu empresa.
          </p>
          <a href="https://wa.me/573161392317?text=hola" target="_blank" rel="noopener noreferrer">
            <Button size="sm" className="text-sm px-8 py-4 bg-green-500">
              Habla con nosotros
            </Button>
          </a>

        </div>
        <div className="md:w-1/4 mt-8 md:mt-0">
          <img 
            src="./105.png" 
            alt="Bot de WhatsApp Conversational Solutions" 
            width={600} 
            height={400} 
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  </section>
);

export default ConversationalProjectSection;
