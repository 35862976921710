import { Brain, HelpCircle, Database, Settings } from "lucide-react";

export default function Funcionalidades() {
  const features = [
    {
      title: "Entrenamiento y contexto",
      icon: Brain,
      content: [
        "Desarrolla el contexto específico de tu organización para que tu asistente de IA sea más listo",
        "Actualiza en tiempo real el conocimiento del asistente con el feedback de tus clientes y equipo",
        "Conecta tus canales con GPTs de ChatGPT para ilimitadas opciones de personalización y entrenamiento avanzados"
      ]
    },
    {
      title: "Asistencia",
      icon: HelpCircle,
      content: [
        "Pregunta a tu asistente con IA de forma interna por las conversaciones:",
        "Resume la conversación",
        "Pregunta por sugerencias en las respuestas",
        "Pregunta por un análisis de la conversación"
      ]
    },
    {
      title: "Memoria",
      icon: Database,
      content: [
        "La IA es capaz de recordar la conversación y actuar en consecuencia:",
        "Define cuántos mensajes previos quieres que la IA tenga en cuenta",
        "Personaliza las respuestas en base a esta información"
      ]
    },
    {
      title: "Comportamiento",
      icon: Settings,
      content: [
        "Define cómo quieres que la IA se comporte:",
        "Personalidad",
        "Tono de voz",
        "Limitaciones",
        "Nivel de creatividad",
        "Estructura de las respuestas",
        "Define dónde quieres que la IA actúe:",
        "In response to free-text messages",
        "In specific chatbot nodes"
      ]
    }
  ];

  return (
    <>
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-light text-center mb-12"><span style={{ fontWeight: 'bold' }}>Funcionalidades</span> disponibles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="bg-white rounded-lg shadow p-6 flex flex-col h-full">
                <div className="flex items-center space-x-2 mb-4">
                  <feature.icon className="w-6 h-6 text-green-500" /> {/* Ícono en verde */}
                  <h3 className="text-lg font-semibold">{feature.title}</h3>
                </div>
                <ul className="space-y-2">
                  {feature.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="text-sm text-muted-foreground">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="py-16 bg-background">
  <div className="container mx-auto px-4">
    <h2 className="text-2xl font-light text-center">
    <span style={{ fontWeight: 'bold' }}>ChatGP</span> funciona con todos nuestros canales
    </h2>
    <div className="flex justify-center mb-6">
      <img
        src="/WhatsApp.svg.png" // Reemplaza con la URL de tu imagen
        alt="Logo"
        width={100}
        height={50}
        className="mx-auto"
      />
    </div>
    <div className="space-y-6 text-center">
      <p className="text-xl font-light">
        Descubre cómo esta extensión puede mejorar tus resultados.
      </p>
      <p className="text-lg font-bold">
        ¿Listo para que la inteligencia artificial lleve tus conversaciones a un nuevo nivel?
      </p>
    </div>
    <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
      <a href="https://wa.me/573161392317?text=demo" className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition">
        Quiero una demo
      </a>
      <a href="https://wa.me/573161392317?text=demo" className="border border-green-500 text-green-500 py-2 px-4 rounded-lg hover:bg-blue-100 transition">
        Comenzar ahora
      </a>
    </div>

  </div>
</section>

    </>
  );
}
