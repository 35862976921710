import React from 'react';

const steps = [
  "Contacta con nuestro equipo",
  "Firma el contrato",
  "Acompañamiento en ventas",
  "Formación continua",
  "Conviértete en Country Partner"
];

export default function PartnerNetworkSteps() {
  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-light text-gray-900 text-center mb-12">
          Únete a nuestra exclusiva Red de <span style={{ fontWeight: 'bold' }}>Partners</span>
        </h2>
        <div className="flex flex-wrap justify-center gap-8">
          {steps.map((step, index) => (
            <div key={index} className="relative w-40 h-40 flex flex-col items-center justify-center">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-green-100 text-9xl font-bold opacity-25 select-none">
                  {index + 1}
                </div>
              </div>
              <div className="z-10 text-center">
                <div className="w-10 h-10 rounded-full bg-green-100 text-green-600 text-xl font-bold flex items-center justify-center mb-2 mx-auto">
                  {index + 1}
                </div>
                <p className="text-sm text-gray-700">{step}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <a
            href="https://wa.me/573161392317?text=partners" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-green-500 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300"
          >
            Conviértete en partner
          </a>

        </div>
      </div>
    </div>
  );
}