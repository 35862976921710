import { Facebook, Twitter, Linkedin, Instagram } from 'lucide-react';
import '../App.css';

const Footer = () => (
  <footer className="bg-gray-800 text-white py-12">
    <div className="container mx-auto px-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">Sobre Bot de WhatsApp</h3>
          <p className="text-sm">
            Bot de WhatsApp es una plataforma líder en soluciones de WhatsApp para empresas, ofreciendo servicios de marketing, ventas y atención al cliente.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Enlaces rápidos</h3>
          <ul className="text-sm">
            {[
              { name: 'Inicio', link: '/' },
              { name: 'Soluciones', link: '/soluciones' },
              { name: 'Chatbots', link: '/chatbots' },
              { name: 'Integraciones', link: '/integraciones' },
              { name: 'Funcionalidades', link: '/funcionalidades' },
              { name: 'Planes', link: '/planes' },
              { name: 'Partners', link: '/partners' },
            ].map(({ name, link }) => (
              <li key={name} className="mb-2">
                <a href={link} className="hover:text-green-500">{name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Contacto</h3>
          <p className="text-sm mb-2">Email: bucaramarketing@gmail.com</p>
          <p className="text-sm mb-2">Teléfono: +57 316 139 2317</p>
          <p className="text-sm">Dirección: Calle Principal, 27, Bucaramanga, Colombia</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Síguenos</h3>
          <div className="flex space-x-4">
            <a href="https://facebook.com/botdewhatsapp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500">
              <Facebook />
            </a>
            <a href="https://twitter.com/botdewhatsapp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500">
              <Twitter />
            </a>
            <a href="https://linkedin.com/botdewhatsapp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500">
              <Linkedin />
            </a>
            <a href="https://instagram.com/botdewhatsapp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500">
              <Instagram />
            </a>
          </div>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-700 text-center text-sm">
        <p>
          &copy; {new Date().getFullYear()} Bot de WhatsApp. Todos los derechos reservados por 
          <a href="https://bucaramarketing.agency" target="_blank" rel="noopener noreferrer" className="hover:text-green-500"> Bucaramarketing</a>.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
