import { CreditCard, Wrench, Store, Shuffle } from "lucide-react";

export default function Component() {
  const capitalizations = [
    { icon: CreditCard, text: "Reventa de licencias" },
    { icon: Wrench, text: "Servicio de implementación" },
    { icon: Store, text: "Marketplace de chatbots para plantillas y plugins" },
    { icon: Shuffle, text: "Cross-selling" },
  ];

  return (
    <div className="w-full max-w-screen-lg mx-auto bg-white"> {/* Fondo blanco en el contenedor principal */}
      <div className="w-full max-w-4xl bg-white p-4 rounded-lg shadow-md"> {/* Tarjeta sin fondo gris */}
        <h2 className="text-2xl font-light text-center mb-4">
          ¿Cómo podrás capitalizar nuestro <span style={{ fontWeight: 'bold' }}>partnership</span>?
        </h2>
        <div className="flex flex-col md:flex-row justify-around"> {/* Cambiado a flex-col para móviles */}
          {capitalizations.map((item, index) => (
            <div key={index} className="flex flex-col items-center mb-4 md:mb-0"> {/* Margen en la parte inferior para móviles */}
              <div className="bg-green-100 p-3 rounded-full mb-2">
                <item.icon className="h-6 w-6 text-green-600" />
              </div>
              <span className="text-lg font-medium text-center">{item.text}</span> {/* Texto centrado */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
