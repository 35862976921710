import React from 'react';

const WhatsAppMassCustomerService: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 md:pr-8">
            <h2 className="text-3xl font-light mb-4">
              Envíos Masivos de <span style={{ fontWeight: 'bold' }}>WhatsApp</span> para Atención al Cliente
            </h2>
            <h3 className="text-xl font-semibold mb-6">
              Ofrece a tus clientes una experiencia única y personalizada.
            </h3>
            <p className="text-lg mb-6">
              Responde a sus consultas, soluciona problemas y fomenta una relación duradera, todo a través de WhatsApp.
            </p>
            <p className="text-lg mb-6">
              Podrás realizar envíos Masivos de WhatsApp para cualquier ocasión para rellenar una encuesta NPS impulsada por un chatbot, o simplemente para pedir feedback de tus nuevos lanzamientos o tu relación con tus clientes.
            </p>
            <p className="text-lg mb-8">
              Bot de WhatsApp también te permite la posibilidad de tener un Inbox multiagente y multicanal por lo que podrás optimizar tus esfuerzos con una sola plataforma conversacional.
            </p>
            <a href="https://wa.me/573161392317?text=funcionalidades" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300">
              Habla con nosotros
            </a>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img
              src="/107.jpg"
              alt="WhatsApp Mass Customer Service"
              width={600}
              height={400}
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppMassCustomerService;
