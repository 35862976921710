const ContactForm: React.FC = () => {
    return (
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Sección de introducción */}
          <section className="flex-1">
            <h2 className="text-3xl font-light mb-6">¿Listo para iniciar tu viaje <span style={{ fontWeight: 'bold' }}>conversacional</span>?</h2>
            <p className="text-lg mb-6">
              Ponte en contacto con nuestro equipo de expertos y te llamarán para reservar una cita en la que te explicarán todo lo que necesitas saber para comenzar a utilizar el poder de las conversaciones en tu organización.
            </p>
            <a href="https://wa.me/573161392317?text=hola" target="_blank" rel="noopener noreferrer">
              <button className="mb-8 bg-green-500 hover:bg-blue-600 text-white py-2 px-4 rounded">
                Habla con nuestro equipo
              </button>
            </a>

            <img
              src="/106.png"
              alt="Equipo de expertos"
              width={375}
              height={225}
              className="rounded-lg"
            />
          </section>
  
          {/* Sección de formulario */}
          <section className="flex-1">
            <form className="space-y-6">
              <div>
                <label htmlFor="fullName" className="block mb-2">Nombre completo*</label>
                <input id="fullName" required className="border rounded w-full p-2" />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2">Email corporativo*</label>
                <input id="email" type="email" required className="border rounded w-full p-2" />
              </div>
              <div>
                <label htmlFor="country" className="block mb-2">País</label>
                <select id="country" className="border rounded w-full p-2">
                  <option value="">Selecciona tu país</option>
                  <option value="es">España</option>
                  <option value="mx">México</option>
                  <option value="ar">Argentina</option>
                  <option value="ar">Ecuador</option>
                  <option value="ar">Chile</option>
                  <option value="ar">Venezuela</option>
                  <option value="ar">Colombia</option>
                  <option value="ar">Peru</option>
                  <option value="ar">Uruguay</option>
                  <option value="ar">Bolivia</option>
                  <option value="ar">EEUU</option>

                  {/* Agrega más países según sea necesario */}
                </select>
              </div>
              <div className="flex gap-4">
                <div className="w-1/3">
                  <label htmlFor="prefix" className="block mb-2">Prefijo</label>
                  <input id="prefix" className="border rounded w-full p-2" />
                </div>
                <div className="w-2/3">
                  <label htmlFor="whatsapp" className="block mb-2">WhatsApp*</label>
                  <input id="whatsapp" required className="border rounded w-full p-2" />
                </div>
              </div>
              <div>
                <label htmlFor="website" className="block mb-2">Página web*</label>
                <input id="website" required className="border rounded w-full p-2" />
              </div>
              <div>
                <label htmlFor="project" className="block mb-2">Háblanos sobre tu proyecto*</label>
                <textarea id="project" required className="border rounded w-full p-2" />
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" id="privacy" required className="mr-2" />
                  <label htmlFor="privacy">
                    Acepto la política de privacidad y los términos y condiciones.*
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <input type="checkbox" id="marketing" className="mr-2" />
                  <label htmlFor="marketing">
                    Acepto recibir emails y WhatsApp con comunicaciones de marketing de Bot de WhatsApp.
                  </label>
                </div>
              </div>
              <button type="submit" className="w-full bg--500 hover:bg-blue-600 text-white py-2 rounded">
                Enviar
              </button>
            </form>
          </section>
        </div>
      </div>
    );
  };
  
  export default ContactForm;
  