import ClientsSection from '../componentes/ClientsSection';
import SlideSoluciones from '../componentes/SlideSoluciones';
import WhatsAppVsEmail from '../componentes/WhatsappvsEmail';
import WhatsAppMassOperations from '../componentes/WhatsAppMassOperations';
import WhatsAppMassCustomerService from '../componentes/WhatsAppMassCustomerService';
import WhatsAppAIResponses from '../componentes/WhatsAppAIResponses';
import ContactForm from '../componentes/ContactForm';
import FAQ from '../componentes/Faq';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

export default function Funcionalidades() {
  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="md:w-1/2 space-y-6">
            <h2 className="text-3xl font-bold">MENSAJES MASIVOS DE WHATSAPP</h2>
            <h3 className="text-xl font-light text-muted-foreground">
              Envía miles de mensajes de WhatsApp de la forma más sencilla para potenciar tus resultados de marketing, más ventas y mejorar las notificaciones a tus clientes.
            </h3>
            <p className="text-light text-muted-foreground">
              Logra 95% de tasa de apertura con el canal favorito de tus usuarios, comunica todo tipo de acciones con los elementos exclusivos de WhatsApp Cloud y convierte cada interacción en una oportunidad de negocio.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a href="https://wa.me/573161392317?text=funcionalidades" className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition">
                Habla con nosotros
              </a>
              <a href="https://wa.me/573161392317?text=funcionalidades" className="border border-green-500 text-green-500 py-2 px-4 rounded-lg hover:bg-blue-100 transition">
                Empieza hoy
              </a>
            </div>

          </div>
          <div className="md:w-1/2">
            <img
              src="/106.jpg" // Reemplaza con la URL de tu imagen
              alt="Mensajes Masivos de WhatsApp"
              width={600}
              height={400}
              className="w-full h-auto rounded-lg" // Eliminado shadow-lg
            />
          </div>
        </div>
      </div>
      <ClientsSection /> {/* Aquí se agrega el componente */}
      <WhatsAppVsEmail />
      <SlideSoluciones />
      <WhatsAppMassOperations />
      <WhatsAppMassCustomerService />
      <WhatsAppAIResponses />
      <ContactForm />
      <FAQ />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </section>
  );
}
