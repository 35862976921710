import '../App.css';


const Chatgpt = () => (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">
        <span className="font-light">Crea un chatbot conectado a</span> WhatsApp y ChatGPT <span className="font-light">que trabaje por ti 24/7</span>
        </h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0"> {/* Cambiado a pr-8 para padding a la derecha */}
            <h3 className="text-2xl font-bold mb-4">Conversaciones inteligentes con WhatsApp y ChatGPT</h3>
            <h3 className="text-xl text-gray-400 font-bold mb-4">Combina la eficacia de WhatsApp API y la inteligencia de ChatGPT para vender más y reducir costes.</h3>
            <p className="mb-4">
              Con Bot de WhatsApp podrás conectar tu propio GPT de ChatGPT a WhatsApp, Instagram, Facebook Messenger y Webchat para resolver cualquier tipo de consultas de tus clientes o leads, no solamente basado en información general sino en las propias instrucciones de configuración del modelo, podrás desbloquear un mundo de posibilidades para tu negocio.
            </p>
            <a
              href="https://wa.me/573161392317?text=hola" // Reemplaza con la URL deseada
              target="_blank" // Abre el enlace en una nueva pestaña
              rel="noopener noreferrer" // Mejora la seguridad al abrir en una nueva pestaña
              className="mt-4 px-6 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600 transition duration-200"
            >
              Conocer más
            </a>

          </div>
          <div className="md:w-1/2">
            <img src="./104.png?height=300&width=500&text=WhatsApp+Benefits" alt="WhatsApp Benefits" width={500} height={300} />
          </div>
        </div>
      </div>
    </section>
  );

export default Chatgpt;