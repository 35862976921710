import React from 'react';

export default function ContactExperts() {
  return (
    <div className="flex flex-col items-center justify-center min-h-[200px] bg-white p-8">
      <h2 className="text-3xl font-light text-center mb-6">
        ¿Quieres una <span style={{ fontWeight: 'bold' }}>solución</span> personalizada?
      </h2>
      <a
        href="https://wa.me/573161392317?text=planes" // Reemplaza con la URL deseada
        target="_blank" // Abre el enlace en una nueva pestaña
        rel="noopener noreferrer" // Mejora la seguridad al abrir en una nueva pestaña
        className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
      >
        Contacta a nuestros expertos
      </a>

    </div>
  );
}
