import React, { useState, useEffect, useRef } from 'react';
import { Send, MoreVertical, ChevronLeft, Minus } from 'lucide-react';

interface Message {
  text: string | React.ReactNode;
  sender: 'user' | 'bot';
  timestamp: string;
}

const WhatsAppChatbot: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isMinimized, setIsMinimized] = useState(true);
  const [buttonColor, setButtonColor] = useState('#f59e0b');
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const colorIntervalRef = useRef<number | null>(null);

  const menuOptions = [
    "Ver precios",
    "Hacer un pedido",
    "Consultar estado de pedido",
    "Hablar con un agente"
  ];

  const WhatsAppButtonMenu: React.FC = () => (
    <div className="bg-white rounded-lg shadow-md p-4 mt-2">
      <div className="space-y-2">
        {menuOptions.map((option, index) => (
          <button
            key={index}
            className="w-full text-left text-[#075e54] border border-[#075e54] hover:bg-[#e9edef] transition-colors duration-200 p-2 rounded"
            onClick={() => handleMenuOptionClick(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    const welcomeMessage: Message = {
      text: "¡Bienvenido al Bot de WhatsApp! Estoy aquí para ayudarte. ¿En qué puedo asistirte hoy?",
      sender: 'bot',
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };

    setMessages([welcomeMessage]);

    const menuMessage: Message = {
      text: <WhatsAppButtonMenu />,
      sender: 'bot',
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };

    setTimeout(() => {
      setMessages(prev => [...prev, menuMessage]);
    }, 500);
  }, []);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    colorIntervalRef.current = window.setInterval(() => {
      setButtonColor(prev => (prev === '#f59e0b' ? '#4caf50' : '#f59e0b'));
    }, 500);

    return () => {
      if (colorIntervalRef.current) {
        clearInterval(colorIntervalRef.current);
      }
    };
  }, []);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage: Message = {
      text: inputMessage,
      sender: 'user',
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');

    setTimeout(() => {
      const userMessage = inputMessage.toLowerCase();
      let botResponse: Message;

      if (userMessage === "menú") {
        botResponse = {
          text: <WhatsAppButtonMenu />,
          sender: 'bot',
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        };
      } else {
        botResponse = {
          text: "Gracias por tu mensaje. Estoy procesando tu solicitud y te responderé en breve.",
          sender: 'bot',
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        };
      }

      setMessages(prev => [...prev, botResponse]);

      if (audioRef.current) {
        audioRef.current.play();
      }
    }, 1000);
  };

  const handleMenuOptionClick = (option: string) => {
    let botResponse: Message;

    if (option === "Hablar con un agente") {
      botResponse = {
        text: "Te estoy conectando con un agente, un momento por favor...",
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };

      setMessages(prev => [...prev, botResponse]);

      setTimeout(() => {
        window.open("https://wa.me/573161392317?text=hola", "_blank");
      }, 1000); // Espera un segundo antes de abrir el enlace
    } else if (option === "Ver precios") {
      botResponse = {
        text: "Puedes ver los precios aquí: [Ver precios](/precios)", 
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
    } else {
      botResponse = {
        text: `Has seleccionado: ${option}. ¿En qué puedo ayudarte con respecto a esto?`,
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      };
    }

    setMessages(prev => [...prev, botResponse]);

    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(prev => !prev);
    if (!isMinimized && colorIntervalRef.current) {
      clearInterval(colorIntervalRef.current);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 w-80">
      <audio ref={audioRef} src="./whatsapp-apple.mp3" preload="auto" />
      {!isMinimized ? (
        <div className="flex flex-col h-[600px] border rounded-lg shadow-lg bg-[#f0f2f5]">
          <div className="bg-[#075e54] text-white p-4 flex items-center">
            <ChevronLeft className="mr-2 cursor-pointer" />
            <div className="flex items-center flex-grow">
              <img
                src="/logo.png"
                alt="WhatsApp Bot"
                width={40}
                height={40}
                className="rounded-full mr-3"
              />
              <div>
                <h2 className="font-semibold">Bot de WhatsApp</h2>
                <p className="text-xs">En línea</p>
              </div>
            </div>
            <div className="flex items-center">
              <MoreVertical className="mr-4 cursor-pointer" />
              <button onClick={toggleMinimize} className="text-white">
                <Minus />
              </button>
            </div>
          </div>

          <div className="flex-grow p-4 bg-[#e5ddd5] overflow-auto" ref={scrollAreaRef}>
            {messages.map((message, index) => (
              <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block p-2 rounded-lg ${message.sender === 'user' ? 'bg-[#dcf8c6]' : 'bg-white'} max-w-[70%]`}>
                  {typeof message.text === 'string' ? (
                    <p className="text-sm">{message.text}</p>
                  ) : (
                    message.text
                  )}
                  <p className="text-xs text-gray-500 mt-1">{message.timestamp}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="p-2 bg-[#f0f2f5]">
            <div className="flex items-center bg-white rounded-full">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Escribe un mensaje"
                className="flex-grow border-none focus:ring-0 rounded-full p-2"
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button
                onClick={handleSendMessage}
                className="rounded-full bg-[#075e54] hover:bg-[#128c7e] h-10 w-10 p-0 ml-2 flex items-center justify-center"
              >
                <Send className="h-5 w-5 text-white" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={toggleMinimize}
          className="bg-[#075e54] text-white p-2 shadow-lg flex items-center rounded-md"
          style={{ backgroundColor: buttonColor }}
        >
          <img 
            src="./logo.png" 
            alt="Descripción de la imagen" 
            className="w-8 h-8 rounded-full"
          />
          <MoreVertical />
          <span className="ml-2">Bot de WhatsApp</span>
        </button>
      )}
    </div>
  );
};

export default WhatsAppChatbot;
