import React, { useState, useEffect } from 'react';

const images = [
  '/10.png',
  '/11.png',
  '/12.png',
  '/13.png',
  '/14.png',
];

const WhatsAppMassMarketing: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Cambia de imagen cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-start justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0 pr-8">
            <h2 className="text-3xl font-light mb-6">
              Mensajes Masivos de <span style={{ fontWeight: 'bold' }}>WhatsApp</span> en Marketing
            </h2>
            <p className="text-lg mb-6">
              ¡Descubre el poder ilimitado de los envíos Masivos de WhatsApp! Podrás realizar envíos de WhatsApp a cualquier base de datos, sin restricciones en el número de envíos. Lo más importante para tu empresa es que puedes medir el impacto de tus campañas en tiempo real, gracias a sus detalladas métricas de apertura e interacción.
            </p>
            <p className="text-lg mb-6">
              Con los envíos Masivos de WhatsApp para marketing, las posibilidades son infinitas: desde recontactar bases de datos hasta lanzar ofertas irresistibles y promociones relámpago con una validez de 24 horas.
            </p>
            <p className="text-lg mb-8">
              Tendrás acceso a mensajes exclusivos que solo los usuarios de WhatsApp podrán utilizar como: Mensajes, Carruseles, Productos, mensajes que desaparecen en menos de 24 horas, perfectos para crear una sensación de urgencia y exclusividad en tus ofertas.
            </p>
            <a href="https://wa.me/573161392317?text=funcionalidades" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300">
              Habla con nosotros
            </a>

          </div>
          <div className="md:w-1/2">
            <div className="relative h-[708px] w-[327px] mx-auto">
              <img
                src={images[currentImageIndex]}
                alt={`WhatsApp Marketing ${currentImageIndex + 1}`}
                className="rounded-lg w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppMassMarketing;
