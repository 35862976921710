'use client'

import { useState, useEffect } from 'react'

export default function Slidetext() {
  const [currentImage, setCurrentImage] = useState(0)

  const images = [
    '/10.png?text=Image1',
    '/11.png?text=Image2',
    '/12.png?text=Image3',
    '/13.png?text=Image4',
    '/14.png?text=Image5',
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length)
    }, 3000)

    return () => clearInterval(timer)
  }, [])

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-3/4 md:pr-8 mb-8 md:mb-0">
            <h2 className="text-3xl font-light mb-6">
              Además, aprovecha todas las funcionalidades exclusivas de <span style={{ fontWeight: 'bold' }}>Bot de WhatsApp</span>
            </h2>
            <p className="text-base text-muted-foreground mb-6 font-light">
              Nuestros chatbots utilizan los objetos exclusivos de WhatsApp, permitiendo el WhatsApp Flow. Además, permite utilizar el resto de funcionalidades de Bot de WhatsApp, como sus integraciones y el gestor exclusivo de chats en vivo.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-muted-foreground">
              <li>Herramienta de chat en vivo multi agente y multi canal</li>
              <li>Integraciones pre diseñadas con diferentes tecnologías</li>
              <li>Soluciones para eCommerce y pagos conversacionales</li>
              <li>Adapta las respuestas al comportamiento con los objetos de WhatsApp</li>
              <li>Configura ChatGPT para actuar en todo el chatbot o solo en momentos concretos</li>
              <li>Seguridad y privacidad en tus conversaciones</li>
            </ul>
          </div>
          <div className="md:w-1/4">
            <div className="relative w-full h-60 md:h-[700px] rounded-lg overflow-hidden"> {/* Ajustar la altura aquí */}
              {images.map((src, index) => (
                <img
                  key={src}
                  src={src}
                  alt={`Feature image ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: index === currentImage ? 1 : 0,
                    transition: 'opacity 0.5s ease-in-out',
                    position: 'absolute', // Asegura el apilamiento
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
