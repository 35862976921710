'use client'

import { useState, useCallback } from 'react'
import { Upload, Loader2, Link, Copy } from 'lucide-react'

export default function Subirimagen() {  // Cambié 'subirimagen' a 'Subirimagen'
  const [file, setFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState(false)
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null)
  const [message, setMessage] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
    })
  }, [])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0])
    }
  }

  const uploadImage = async () => {
    if (!file) {
      setMessage('Por favor selecciona una imagen.')
      return
    }

    setUploading(true)
    setMessage('')

    const reader = new FileReader()
    reader.onloadend = async () => {
      const arrayBuffer = reader.result as ArrayBuffer
      await uploadImageToImgBB(arrayBuffer, file.name)
    }
    reader.readAsArrayBuffer(file)
  }

  const uploadImageToImgBB = async (imageBuffer: ArrayBuffer, filename: string) => {
    const formData = new FormData()
    formData.append('image', new Blob([imageBuffer]), filename)
    formData.append('key', 'de1dc835d4f24dddc4df4be7ed3a8298')

    try {
      const response = await fetch('https://api.imgbb.com/1/upload', {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success) {
          setUploadedImageUrl(result.data.url)
          setMessage('Imagen subida exitosamente')
        } else {
          setMessage('Error al subir imagen: ' + (result.error?.message || 'Error desconocido'))
        }
      } else {
        setMessage('Error en la respuesta de ImgBB: ' + response.statusText)
      }
    } catch (error) {
      setMessage('Error al subir imagen: ' + (error as Error).message)
    } finally {
      setUploading(false)
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2072&auto=format&fit=crop')] bg-cover bg-center bg-no-repeat">
      <div className="w-full max-w-md bg-gray-800/60 backdrop-blur-md text-white border-gray-700 p-4 rounded-md">
        <div className="space-y-6 text-center">
          <div className="py-4">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/whatsapp-bots-SPaQIGThDQgSBkpBnmyFwcvAtgbIAD.png"
              alt="WhatsApp Bot Logo"
              className="object-contain w-full"
              style={{ width: '300px', height: '75px' }}
            />
          </div>
          {uploadedImageUrl && (
            <div className="w-full h-48 relative overflow-hidden rounded-lg mb-4">
              <img
                src={uploadedImageUrl}
                alt="Imagen subida"
                className="w-full h-full object-cover"
              />
            </div>
          )}
        </div>
        <div className="space-y-4">
          <div className="relative group">
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
            <label
              htmlFor="imageInput"
              className="flex items-center justify-center w-full p-4 text-center border-2 border-dashed rounded-md cursor-pointer border-gray-600 hover:border-[#25D366] transition-colors duration-300"
            >
              {file ? (
                <span className="text-[#25D366]">{file.name}</span>
              ) : (
                <span className="text-gray-400">Seleccionar imagen...</span>
              )}
            </label>
            <div className="absolute inset-0 bg-[#25D366]/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none rounded-md"></div>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4 mt-4">
          <button
            onClick={uploadImage}
            disabled={uploading || !file}
            className="w-full bg-[#25D366] hover:bg-[#20b858] text-white transition-all duration-300 transform hover:scale-105 rounded-md"
          >
            {uploading ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Upload className="mr-2 h-4 w-4" />
            )}
            {uploading ? 'Subiendo...' : 'Subir Imagen'}
          </button>
          {message && (
            <p className={`text-center ${message.includes('Error') ? 'text-red-400' : 'text-green-400'}`}>
              {message}
            </p>
          )}
          {uploadedImageUrl && (
            <div className="flex flex-col items-center space-y-2 w-full">
              <div className="flex items-center space-x-2 w-full">
                <Link className="h-4 w-4 text-[#25D366]" />
                <input
                  type="text"
                  value={uploadedImageUrl}
                  readOnly
                  className="flex-grow bg-transparent text-sm text-[#25D366] outline-none"
                />
                <button
                  onClick={() => copyToClipboard(uploadedImageUrl)}
                  className="p-2 h-10 w-10 flex items-center justify-center text-[#25D366] border-2 border-[#25D366] rounded-md hover:bg-[#25D366]/10 transition-all duration-300"
                >
                  <Copy className="h-4 w-4" />
                  <span className="sr-only">Copiar enlace</span>
                </button>
              </div>
              {copySuccess && (
                <p className="text-green-400 text-sm">Enlace copiado al portapapeles</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
