import { useState } from 'react';

export default function Preguntas() {
  const faqs = [
    {
      question: "¿Cómo puede ChatGPT mejorar mi atención al cliente en WhatsApp?",
      answer: "ChatGPT puede proporcionar respuestas instantáneas y personalizadas a las consultas de los clientes, mejorando los tiempos de respuesta y la satisfacción del cliente. Además, puede manejar múltiples conversaciones simultáneamente, liberando a tu equipo para tareas más complejas."
    },
    {
      question: "¿Es posible integrar ChatGPT con mis sistemas existentes?",
      answer: "Sí, nuestro equipo puede desarrollar integraciones personalizadas para conectar ChatGPT con tus sistemas CRM, bases de datos y otras herramientas existentes, permitiendo una experiencia fluida y coherente en todos tus canales."
    },
    {
      question: "¿Cómo se maneja la privacidad y seguridad de los datos?",
      answer: "Implementamos rigurosas medidas de seguridad y cumplimos con las normativas de protección de datos. Todos los datos se cifran y se manejan de acuerdo con las mejores prácticas de la industria para garantizar la privacidad y seguridad de tu información y la de tus clientes."
    },
    {
      question: "¿Puedo personalizar las respuestas de ChatGPT según mi marca?",
      answer: "Absolutamente. Podemos entrenar a ChatGPT con el tono, estilo y valores de tu marca, asegurando que todas las interacciones sean coherentes con tu identidad corporativa y ofrezcan una experiencia de marca uniforme."
    }
  ];

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-8">
          Desarrollo de soluciones a medida entre <span style={{ fontWeight: 'bold' }}>ChatGPT y Bot de WhatsApp</span>
        </h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/2">
            <p className="text-lg text-muted-foreground mb-4">
              La combinación de ChatGPT y WhatsApp es potente por sí sola, pero con desarrollos a medida, llevamos esa potencia al siguiente nivel.
            </p>
            <p className="text-lg text-muted-foreground">
              Nuestro equipo puede ayudarte para que ChatGPT se adapte a las especificidades y desafíos de tu negocio, garantizando soluciones únicas para cada área de tu organización.
            </p>
          </div>
          <div className="md:w-1/2">
            <div className="border rounded-md">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <button 
                    className="w-full text-left px-4 py-2 bg-gray-100 hover:bg-gray-200 transition"
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                  </button>
                  {openIndex === index && (
                    <div className="px-4 py-2 bg-gray-50">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
