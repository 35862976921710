import React from 'react';

const successStories = [
  {
    title: "Simon Bolivar Art Academy",
    highlight: "Multiplicar sus ventas x4 en 6 meses",
    description: "Para destacar sobre la competencia empezaron a realizar el proceso de cotización y contratación por WhatsApp y no por e-mail o llamadas. Chatbots que facilitan y agilizan el proceso de cotizar seguros para auto sin perder de vista el factor humano.",
    image: "/504.png"
  },
  {
    title: "Italian Pizza",
    highlight: "12% Incremento del ratio de atención/lead",
    description: "Un canal de comunicación directo en WhatsApp automatizado. Mensajes de respuesta automática usando plantillas de WhatsApp y chatbots para atender de forma inmediata a las decenas de familias que les contactan a diario solicitando más información",
    image: "/502.png"
  },
  {
    title: "Dental Care",
    highlight: "Incrementar su tasa de conversión en un 10%",
    description: "La implementación de Bot de WhatsApp ha mejorado significativamente los KPI de Longevita. Las notificaciones integradas de WhatsApp en su CRM han mejorado las tasas de conversión y agilizado el proceso de reserva.",
    image: "/507.png"
  }
];

const ClientSuccessStories: React.FC = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-12">
          Cómo nuestros clientes ya utilizan <span style={{ fontWeight: 'bold' }}>chatbots en Whatsapp para vender</span> más
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {successStories.map((story, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <div className="w-16 h-16 mr-4 flex-shrink-0">
                    <img
                      src={story.image}
                      alt={story.title}
                      className="rounded-full object-cover w-full h-full"
                    />
                  </div>
                  <h3 className="text-xl font-semibold">{story.title}</h3>
                </div>
                <p className="text-green-600 font-semibold mb-4">{story.highlight}</p>
                <p className="text-gray-600">{story.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientSuccessStories;
