import React from 'react';

const partners = [
  {
    name: "Simon Bolivar Art Academy",
    earnings: "9600",
    description: "Experto en mensajería empresarial que ofrece consultoría profesional y soluciones de chatbot personalizadas para empresas.",
    location: "Republica Dominicana",
    logo: "/504.png" // Logo placeholder
  },
  {
    name: "Guimo's",
    earnings: "18400",
    description: "Guimo's es una empresa que proporciona soluciones de integración personalizadas, formación y consultoría.",
    location: "Mexico",
    logo: "/500.png" // Logo placeholder
  },
  {
    name: "Guimo's",
    earnings: "16000",
    description: "Consultores en Ventas y Estrategias Digitales.",
    location: "EEUU",
    logo: "/502.png" // Logo placeholder
  }
];

export default function PartnerEarnings() {
  return (
    <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-light text-gray-900 text-center mb-12">
          ¿Cuánto <span style={{ fontWeight: 'bold' }}>ganan</span> al año nuestros partners?
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {partners.map((partner, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <img
                    src={partner.logo}
                    alt={`${partner.name} logo`}
                    width={80}
                    height={80}
                    className="rounded-full"
                  />
                  <div className="text-right">
                    <p className="text-sm text-gray-500">Ganancias anuales</p>
                    <p className="text-2xl font-bold text-green-600">${partner.earnings}</p>
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{partner.name}</h3>
                <p className="text-gray-600 mb-4">{partner.description}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <svg className="h-5 w-5 mr-1" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  {partner.location}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
