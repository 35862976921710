export default function Youtube() {
    return (
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-light text-center mb-8">¿Quieres ver a <span style={{ fontWeight: 'bold' }}>ChatGPT y Bot de WhatsApp</span> en acción?</h2>
          <div className="max-w-3xl mx-auto">
            <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg shadow-lg">
              <iframe
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="ChatGPT Demo Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full border-0"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    )
  }