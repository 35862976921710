import React from 'react';
import HeroRecursos from '../componentes/HeroRecursos'; // Asegúrate de importar HeroRecursos
import { Clock, Infinity, DollarSign, MessageSquare } from 'lucide-react';
import ContactForm from '../componentes/ContactForm';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const ScheduledMessagesSection: React.FC = () => {
  return (
    <>
      <HeroRecursos />
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 mb-8 lg:mb-0">
              <img
                src="/1001.png" // Asegúrate de que la ruta sea correcta
                alt="Programación de Mensajes en WhatsApp"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
            <div className="lg:w-1/2 lg:pl-12">
              <h2 className="text-3xl font-bold mb-6">Programación de Mensajes para Ventas en WhatsApp</h2>
              <p className="text-lg mb-6">
                Optimiza tus estrategias de ventas con nuestra función de programación de mensajes en WhatsApp. 
                Ahora puedes planificar y enviar mensajes de forma automática, directamente desde tu chat de WhatsApp.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="flex items-center">
                  <MessageSquare className="w-8 h-8 text-green-500 mr-3" />
                  <span className="text-lg">Sin bloqueos</span>
                </div>
                <div className="flex items-center">
                  <Infinity className="w-8 h-8 text-blue-500 mr-3" />
                  <span className="text-lg">Mensajes ilimitados</span>
                </div>
                <div className="flex items-center">
                  <DollarSign className="w-8 h-8 text-yellow-500 mr-3" />
                  <span className="text-lg">Totalmente gratis</span>
                </div>
                <div className="flex items-center">
                  <Clock className="w-8 h-8 text-purple-500 mr-3" />
                  <span className="text-lg">Programación fácil</span>
                </div>
              </div>
              <p className="text-lg mb-6">
                Programa tus mensajes de ventas con un simple comando en WhatsApp. 
                Nuestro chatbot procesará tu solicitud y enviará los mensajes en el momento que especifiques.
              </p>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Comando de Programación:</h3>
                <code className="text-lg font-mono bg-gray-200 p-2 rounded">
                  /programar [fecha] [hora] [mensaje]
                </code>
                <p className="mt-2 text-sm text-gray-600">
                  Ejemplo: /programar 2023-10-20 14:30 ¡Oferta especial solo por hoy!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </>
  );
};

export default ScheduledMessagesSection;
