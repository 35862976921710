import { MessageSquare, Users, Bot, Database } from 'lucide-react';
import '../App.css';


const IconSection = () => (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <div className="flex flex-col items-center text-center">
            <MessageSquare className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Chat en vivo multi-agente y multi-canal</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <Bot className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Chatbots de generación y cualificación de leads</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <Database className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Integración con CRM</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <Users className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Gestión de equipos de ventas</h4>
          </div>
        </div>
      </div>
    </section>
  );

export default IconSection;