import { useState } from 'react';
import { Menu } from 'lucide-react'; // Solo necesitas el ícono del menú
import { Link } from 'react-router-dom'; // Importar Link
import '../App.css';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar si el menú está abierto

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Cambia el estado del menú
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Cierra el menú al hacer clic en un enlace
  };

  return (
    <nav className="shadow-md z-50" style={{ backgroundColor: '#fcf5eb' }}>
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        {/* Logo a la izquierda */}
        <Link to="/" rel="noopener noreferrer">
          <img
            src="./whatsapp-bots.png?height=40&width=40"
            alt="Logo"
            width={200}
            height={200}
            className="mr-4"
          />
        </Link>

        {/* Ícono del menú para móviles */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none" aria-expanded={isOpen}>
            <Menu size={24} />
          </button>
        </div>

        {/* Menú de navegación */}
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } w-full md:flex md:items-center md:justify-center md:w-auto transition-all duration-300 ease-in-out`}
        >
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-4 md:mt-0">
            {['Inicio', 'Soluciones', 'Chatbots', 'Integraciones', 'Funcionalidades', 'Planes', 'Partners', 'Recursos'].map((item) => (
              <li key={item}>
                <Link
                  to={item === 'Inicio' ? '/' : item === 'Soluciones' ? '/soluciones' : `/${item.toLowerCase()}`}
                  className="nav-link text-gray-700 hover:text-gray-900"
                  onClick={handleLinkClick} // Cierra el menú al hacer clic
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Botón de contacto */}
        <div className="hidden md:block">
          <a href="https://wa.me/573161392317?text=contacto" target="_blank" rel="noopener noreferrer">
            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Contacto
            </button>
          </a>
        </div>

      </div>
    </nav>
  );
};

export default Navigation;
