import '../App.css';

const ConversationalCommerceSection = () => (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Conversational Commerce</h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img src="./103.png?height=300&width=500&text=WhatsApp+Benefits" alt="WhatsApp Benefits" width={500} height={300} />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h3 className="text-2xl font-bold mb-4">Aumenta las ventas de tu e-commerce con WhatsApp</h3>
            <p className="mb-4">
              Añade el canal más eficaz a tu estrategia de venta online. Potencia tu marketing, servicio al cliente y ventas con Bot de WhatsApp.
            </p>
            <p className="mb-4">
              Recibe pedidos y pagos a través de WhatsApp
            </p>
            <p className="mb-4">
              Tengas o no un eCommerce, si quieres vender productos a través de canales como WhatsApp, sabemos como hacerlo realidad.
            </p>
            <p className="text-xl font-semibold">
              ¡Vende más en tu e-commerce!
            </p>
          </div>
        </div>
      </div>
    </section>
  );

export default ConversationalCommerceSection;