import { Link, ShoppingBasket, Workflow, ChartColumnStacked } from 'lucide-react';
import '../App.css';

const IconSection3 = () => (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <div className="flex flex-col items-center text-center">
            <Link className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Integra tu eCommerce en WhatsApp</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <ShoppingBasket className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Recibe pedidos y pagos a través de WhatsApp</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <Workflow className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Automatización de marketing vía WhatsApp</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <ChartColumnStacked className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Campañas conversacionales con promociones</h4>
          </div>
        </div>
      </div>
    </section>
  );

export default IconSection3;