import '../App.css';

const WhatsAppBenefitsSection = () => (
    <section className="py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8"><span className="font-light">Sácale el</span> máximo partido <span className="font-light">a WhatsApp</span></h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img src="./101.png?height=300&width=500&text=WhatsApp+Benefits" alt="WhatsApp Benefits" width={500} height={300} />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h3 className="text-2xl font-bold mb-4">Marketing y ventas</h3>
            <p className="mb-4">
              Aumenta tus ventas gracias a implementar estrategias conversacionales en WhatsApp.
              En Bot de WhatsApp, hemos comprobado una y otra vez cómo nuestros clientes han obtenido resultados increíbles al comunicarse con sus clientes en los canales que ellos prefieren.
            </p>
            <p className="mb-4">
              Contamos con una amplia experiencia en diversas industrias, y hemos implementado con éxito decenas de estrategias de marketing y ventas utilizando nuestras soluciones.
            </p>
            <p className="text-xl font-semibold">
              ¿Estás listo para llevar tus ventas al siguiente nivel?
            </p>
          </div>
        </div>
      </div>
    </section>
  );

export default WhatsAppBenefitsSection;