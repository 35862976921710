import '../App.css';

const ImageGallerySection = () => {
  // Definir un array con las rutas de las imágenes
  const images = [
    './ChatGPT.png',
    './stripe.png',
    './PayPal.png',
    './dialogflow.png'
  ];

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-8">
        <span className="font-light">Integrado con</span>
      </h2>
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {images.map((image, index) => (
              <div key={index} className="flex items-center justify-center">
                <img src={image} alt={`Gallery Image ${index + 1}`} width={100} height={50} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageGallerySection;
