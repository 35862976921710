import React, { useState, useEffect } from 'react';

const slides = [
  {
    image: '/107.png',
    title: 'Hasta un x3 en tu tasa de conversión',
    description: 'Conecta Eficazmente: WhatsApp es reconocido por tener una de las tasas de apertura y conversión más altas en comparación con otros canales de comunicación. Utiliza este poderoso canal para transformar interacciones ordinarias en oportunidades de ventas significativas y exitosas.'
  },
  {
    image: '/108.png',
    title: 'Reducción de costes a través de la automatización',
    description: 'Automatiza la Cualificación de Leads: Minimiza el esfuerzo manual y los recursos invertidos en la cualificación de leads. Nuestro chatbot inteligente gestiona automáticamente este proceso, asegurando que solo los leads más prometedores lleguen a tus vendedores.'
  },
  {
    image: '/109.png',
    title: 'Procesos de venta más ágiles',
    description: 'Eficiencia en Cada Paso: Acorta los ciclos de venta y mejora la velocidad de respuesta a las consultas de los clientes. Con nuestro chatbot, el tiempo desde el primer contacto hasta el cierre de una venta se reduce significativamente, optimizando cada fase del proceso de venta.'
  },
  {
    image: '/110.png',
    title: 'Integración perfecta con tu stack tecnológico',
    description: 'Sincronización con Sistemas Existentes: Facilitamos la integración de nuestro chatbot con tu stack tecnológico actual. Ya sea CRM, ERP o cualquier otra herramienta, garantizamos una integración sin fisuras para un flujo de trabajo eficiente y una gestión de datos precisa.'
  },
  {
    image: '/111.png',
    title: 'Aumento de la satisfacción de los vendedores',
    description: 'Enfoque en lo que Realmente Importa: Al liberar a tus vendedores de tareas repetitivas y de baja importancia, les permitimos centrarse en interacciones de mayor valor, mejorando su satisfacción y eficacia. Con nuestro chatbot, ellos pueden dedicarse a lo que mejor saben hacer: vender.'
  }
];

const WhatsappSlide: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        setFadeIn(true);
      }, 500); // Wait for fade out before changing slide
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-4">
          Cómo venderás más con un <span style={{ fontWeight: 'bold' }}>chatbot en WhatsApp</span>
        </h2>
        <h3 className="text-xl font-light text-center mb-12">
          ¿Sabías que un 83% de usuarios prefieren conectar con las empresas a través de WhatsApp?
        </h3>
        <div className="flex items-center justify-center">
          <div className="w-full max-w-4xl">
            <div className={`transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                  <img
                    src={slides[currentSlide].image}
                    alt={slides[currentSlide].title}
                    width={500}
                    height={300}
                    className="rounded-lg" // Se eliminó la clase shadow-lg
                  />
                </div>
                <div className="md:w-1/2">
                  <h4 className="text-2xl font-semibold mb-4">{slides[currentSlide].title}</h4>
                  <p className="text-gray-700">{slides[currentSlide].description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-8">
          <p className="text-xl font-light mb-6">
            Habla con nuestro chatbot de ventas y un experto te ayudará a ponerte en marcha
          </p>
          <a href="https://wa.me/573161392317?text=chatbots" target="_blank" rel="noopener noreferrer">
            <button className="bg-green-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300">
              Consulta con un experto
            </button>
          </a>

        </div>
      </div>
    </section>
  );
};

export default WhatsappSlide;
