import { useState, useEffect, useRef } from 'react';
import { AlertCircle, X, Clock } from 'lucide-react';

interface PromoBarProps {
  timeLeft: number;
  formatTime: (time: number) => string;
  onClose: () => void;
}

const PromoBar = ({ timeLeft, formatTime, onClose }: PromoBarProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const promoBarRef = useRef<HTMLDivElement | null>(null); // Referencia al div de la barra promocional

  const playAudio = () => {
    const audio = new Audio('/whatsap.mp3'); // Asegúrate de que la ruta sea correcta
    audio.play().catch(error => console.error("Error al reproducir audio:", error));
  };

  const handleScroll = () => {
    if (window.scrollY > 200 && !isVisible) { // Cambia el valor de 200 según necesites
      setIsVisible(true);
      setShowNotification(true); // Mostrar la notificación cuando la barra se muestra
      playAudio(); // Reproduce el audio aquí
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Limpiar el listener al desmontar
    };
  }, [isVisible]);

  useEffect(() => {
    // Ocultar la notificación después de 3 segundos
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      return () => clearTimeout(timer); // Limpiar el temporizador al desmontar
    }
  }, [showNotification]);

  // Función para hacer scroll hacia la barra de promoción
  const scrollToPromo = () => {
    if (promoBarRef.current) {
      promoBarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Mostrar notificación estilo "toast" de WhatsApp */}
      {showNotification && (
        <div
          className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-white text-black shadow-lg rounded-lg p-2 z-50 flex items-center space-x-2 animate-fade-in-out cursor-pointer"
          onClick={scrollToPromo} // Al hacer clic, lleva a la barra promocional
        >
          <AlertCircle className="text-green-500" />
          <p className="text-sm">Mensaje nuevo</p>
        </div>
      )}

      {/* Barra promocional */}
      {isVisible && (
        <div
          ref={promoBarRef} // Referencia a la barra para hacer scroll
          className="bg-green-500 text-white py-2 px-4 flex items-center justify-between relative"
        >
          {/* Texto a la izquierda */}
          <div className="flex items-center flex-1">
            <AlertCircle className="mr-2" />
            <p className="mr-4 text-sm md:text-base">Aprovecha nuestra oferta antes de que termine:</p>
          </div>

          {/* Ícono de reloj y contador centrado */}
          <div className="flex items-center flex-1 justify-center">
            <Clock className="mr-1 text-green-600" />
            <p className="text-lg font-bold">{formatTime(timeLeft)}</p>
          </div>

          {/* Botón a la derecha */}
          <div className="flex items-center">
            <a
              href="https://wa.me/573161392317?text=promocion" // Reemplaza con la URL deseada
              target="_blank" // Abre el enlace en una nueva pestaña
              rel="noopener noreferrer" // Mejora la seguridad al abrir en una nueva pestaña
              className="bg-blue-600 text-white py-1 px-2 rounded hover:bg-blue-500 text-sm md:text-base"
            >
              Aprovecha esta oportunidad
            </a>
            <X className="cursor-pointer" onClick={onClose} />
          </div>

        </div>
      )}
    </>
  );
};

export default PromoBar;
