import CardSection from "../componentes/CardSection";
import ConversationalProjectSection from "../componentes/ConversationalProjectSection";
import WhatsAppChatbot from "../componentes/WhatsAppChatbot";

export default function Recursos() {
  return (
    <section className="bg-emerald-800 py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl font-extrabold text-white mb-4">Casos de uso</h2>
            <p className="text-white text-lg">
              En esta sección podrás encontrar casos de uso en los que nuestros clientes utilizan las funcionalidades de WhatsApp y Bot de WhatsApp para superar sus retos.
            </p>
          </div>
          <div className="md:w-1/2">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/index-gZtPe73E1UHC04SKmcrdEGL6KRRT2p.jpg"
              alt="WhatsApp chat interface for TrustBank Online"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
      <CardSection />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </section>
  );
}
