import React, { useState } from 'react';

const FAQ: React.FC = () => {
  const faqs = [
    {
      question: "¿Qué tipo de WhatsApp debo de utilizar para realizar envíos Masivos?",
      answer: "En Bot de WhatsApp somos partners, por lo que te recomendamos que lo hagas con WhatsApp Business. Si lo haces con WhatsApp Normal tu número puede ser bloqueado además de tener limitantes a solo 250 personas en los envíos Masivos, y no tienes la trazabilidad."
    },
    {
      question: "¿Cómo puedo empezar para enviar Mensajes Masivos de WhatsApp?",
      answer: "Es muy sencillo. Debes enviar el codigo que te damos y enviarlo al WhatsApp donde esta el bot y ahi envias los mensajes a tu base de datos."
    },
    {
      question: "¿Cuánto cuesta enviar mensajes Masivos por WhatsApp?",
      answer: "Hoy es GRATIS y siempre lo sera."
    },
    {
      question: "¿Puedo ver los resultados de estas acciones de WhatsApp Marketing?",
      answer: "Sí, por supuesto. Podrás acceder a las métricas desde tu propio Bot de WhatsApp sobre la tasa de apertura."
    },
    {
      question: "¿Bot de WhatsApp ofrece algún tipo de ayuda o descuento?",
      answer: "Estaremos encantados de ayudarte. Nuestros planes de Growth y Agency tienen asignado un KAM que será el responsable de ayudarte a la gestión de tus envíos Masivos de WhatsApp, si tienes un volumen serio puedes preguntar a nuestro equipo que te dé algún descuento, siempre están abiertos a negociar."
    },
    {
      question: "¿Los chatbots tienen algún coste extra en Bot de WhatsApp?",
      answer: "Depende del plan que manejes, con nuestros planes de pago podrás utilizar los chatbots que tu empresa necesite para optimizar cada parte de tu funnel de ventas."
    }
  ];

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">Preguntas Frecuentes</h2>
        <div className="w-full max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-300 mb-4">
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full text-left py-4 text-lg font-semibold focus:outline-none"
              >
                {faq.question}
              </button>
              {openIndex === index && (
                <div className="py-2 text-gray-700">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
