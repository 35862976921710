import React from 'react';
import ClientsSection from '../componentes/ClientsSection';
import WhatsappSlide from '../componentes/WhatsappSlide';
import ConnectConverseConvert from '../componentes/ConnectConverseConvert';
import ImageGallerySection from '../componentes/ImageGallerySection';
import WhatsAppCloudAPISection from '../componentes/WhatsAppCloudAPISection';
import ClientSuccessStories from '../componentes/ClientSuccessStories';
import WhyTrustUsSection from '../componentes/WhyTrustUsSection';
import WhatsAppSalesSuccess from '../componentes/WhatsAppSalesSuccess';
import ContactForm from '../componentes/ContactForm';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

export default function PartnerSection() {
  return (
    <div className="bg-white w-full py-16"> {/* Cambiado a w-full */}
      <div className="max-w-full mx-auto"> {/* Cambiado a max-w-full */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 lg:px-8">
            <h1 className="text-4xl font-bold mb-4">Convierte tus conversaciones en Ventas</h1>
            <h3 className="text-2xl font-light text-gray-900 mb-6">Tu Chatbot de ventas en WhatsApp personalizado</h3>
            <p className="text-lg text-gray-700 mb-6">Automatiza y personaliza tus conversaciones. Transforma cada interacción en una oportunidad de venta aprovechando la potencia de WhatsApp.</p>
            <h6 className="text-2xl font-bold text-green-500 mb-6">EMPIEZA A VENDER MAS CON WHATSAPP</h6>
            <div className="space-x-4">
            
              <a href="https://wa.me/573161392317?text=chatbots" target="_blank" rel="noopener noreferrer">
                <button className="border-2 border-green-600 text-green-500 px-6 py-3 rounded-lg">
                  Consulta con un experto
                </button>
              </a>

              <a href="https://wa.me/573161392317?text=chatbots" target="_blank" rel="noopener noreferrer">
                <button className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition duration-300">
                  Empieza ahora hoy
                </button>
              </a>

              <img 
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/meta-whatsapp-TbjDOIaNUsk1yJeldwrqMUNetyGU20.png" 
                alt="Meta WhatsApp" 
                width={300} 
                height={50} 
                className="mb-2 p-5"
              />
              <p className="text-sm text-gray-600 mb-8">
                Socio Comercial de <strong className="font-bold">Meta</strong> y Proveedor de Soluciones Empresariales de{' '}
                <strong className="font-bold">WhatsApp</strong>
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/101.png" // Cambia esto a tu URL o ruta deseada
              alt="Partner con Bot de WhatsApp"
              width={500}
              height={400}
              className="rounded-lg" // Se eliminó la clase shadow-lg
            />
          </div>
        </div>
      </div>
      {/* Componente con fondo gris que ocupa toda la pantalla */}
      <div>
        <ClientsSection />
        <WhatsappSlide />
        <ConnectConverseConvert />
        <ImageGallerySection />
        <WhatsAppCloudAPISection />
        <ClientSuccessStories />
        <WhyTrustUsSection />
        <WhatsAppSalesSuccess />
        <ContactForm />
        <WhatsAppChatbot />
      </div>
    </div>
  );
}
