import Image from './Image'; // Asegúrate de usar el componente de imagen correcto (Next.js o React)
import React from 'react';

const CSRSection = () => (
  <section className="py-16 bg-white">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-light text-center mb-8">
        Responsabilidad <span style={{ fontWeight: 'bold' }}>Social</span> Corporativa
      </h2>
      <div className="flex flex-col md:flex-row items-center">
        {/* Texto descriptivo */}
        <div className="md:w-4/5 mb-8 md:mb-0">
          <p className="text-lg mb-2 font-light">
            En Bot de WhatsApp creemos y llevamos a cabo acciones de Responsabilidad Social Corporativa, porque las empresas tienen un papel fundamental para hacer un mundo mejor.
          </p>
        </div>
        
        {/* Imagen */}
        <div className="md:w-1/5 flex justify-center">
          <img 
            src="./covid.png" 
            alt="Bot de WhatsApp Conversational Solutions" 
            width={100} 
            height={40} 
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  </section>
);

export default CSRSection;
