import React from 'react';
import { CheckCircle } from 'lucide-react';

const features = [
  {
    title: "Interacción Dinámica y Personalizada",
    items: [
      "Comunicación Interactiva: Aprovecha los elementos exclusivos de WhatsApp Cloud API para ofrecer experiencias dinámicas a tus clientes.",
      "Respuestas personalizadas: Ya sea mediante IA o árboles de decisión avanzados, asegura respuestas precisas, inmediatas y adaptadas según las necesidades de cada cliente."
    ]
  },
  {
    title: "Experiencia Cliente & Escalabilidad Humana",
    items: [
      "Respuesta Inmediata: Mejora la experiencia del cliente con respuestas rápidas, 24/7, asegurando la satisfacción y fidelidad de tus clientes.",
      "Transición Fluida: Escala conversaciones complejas a agentes humanos para cuando se necesario garantizar una atención personalizada y detallada."
    ]
  },
  {
    title: "Fácil Integración",
    items: [
      "Conexiones Universales: Integra tu chatbot con cualquier sistema mediante nuestras APIs o conectores como Zapier.",
      "Registro de datos: Haz que tu chatbot tome los datos del cliente, haga las preguntas de cualificación y lo registre y clasifique en tu sistema CRM."
    ]
  },
  {
    title: "Análisis y Mejora Continua",
    items: [
      "Analítica de Conversaciones: Evalúa el ROI de tus campañas y el rendimiento de tus chatbots con métricas precisas.",
      "Insights Cualitativos: Utiliza la IA para obtener análisis detallados de las conversaciones y en tiempo real, permitiendo entender mejor a tus clientes, potenciar a tus agentes y adaptar y mejorar tus estrategias."
    ]
  },
  {
    title: "Adaptabilidad y Gestión Masiva",
    items: [
      "Adaptación a tus Procesos: Personaliza tu chatbot para adaptarse a cualquier proceso de ventas, marketing o atención al cliente.",
      "Gestión a Gran Escala: Maneja miles de conversaciones simultáneas sin comprometer la calidad o la eficiencia."
    ]
  },
  {
    title: "Seguridad y Conformidad",
    items: [
      "Cumplimiento de Normativas: Asegura el cumplimiento de GDPR y otras políticas de privacidad, garantizando la protección de los datos de tus clientes.",
      "Conversaciones Seguras: Con la seguridad inherente de WhatsApp y la obtención del consentimiento, garantiza una comunicación protegida y confiable."
    ]
  }
];

const ConnectConverseConvert: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-light text-center mb-12">Conecta, <span style={{ fontWeight: 'bold' }}>conversa</span>, convierte</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <ul className="space-y-3">
                {feature.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ConnectConverseConvert;