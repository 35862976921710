import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg'; // Para definir tamaños de botón
  onClick?: () => void;
}

const Button = ({ children, className = '', size = 'md', onClick }: ButtonProps) => {
  let sizeClass = '';

  // Definir clases según el tamaño del botón
  switch (size) {
    case 'sm':
      sizeClass = 'text-sm px-3 py-1';
      break;
    case 'md':
      sizeClass = 'text-base px-5 py-2';
      break;
    case 'lg':
      sizeClass = 'text-lg px-8 py-4';
      break;
    default:
      sizeClass = 'text-base px-5 py-2';
  }

  return (
    <button
      className={`bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ${sizeClass} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
