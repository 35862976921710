import React from 'react';

const WhatsAppMassOperations: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="/1000.png"
              alt="WhatsApp Mass Operations"
              width={600}
              height={400}
              className="rounded-lg"
            />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-light mb-4">
              Mensajes Masivos de <span style={{ fontWeight: 'bold' }}>WhatsApp</span> para tus operaciones
            </h2>
            <h3 className="text-xl font-semibold mb-6">
              ¡Deja de enviar los mismos mensajes todos los días de manera manual!
            </h3>
            <p className="text-lg mb-6">
              Olvídate de la tediosa tarea de enviar manualmente los mismos mensajes todos los días. Con nuestra solución innovadora, puedes automatizar tus comunicaciones de manera masiva y eficiente para cualquier tipo de operación que requiera el envío de notificaciones.
            </p>
            <p className="text-lg mb-6">
              Bot de WhatsApp te ofrece una integración fluida con herramientas populares como Zapier y otras plataformas No-Code. Esto significa que puedes conectar fácilmente con tus calendarios, sistemas CRM, o tu ERP, y automatizar esos mensajes que antes enviabas manualmente o a través de otros canales como email o SMS.
            </p>
            <p className="text-lg mb-8">
              Con Bot de WhatsApp, puedes centrarte en lo que realmente importa, mientras nosotros nos encargamos de mantener a tus clientes informados y comprometidos.
            </p>
            <a href="https://wa.me/573161392317?text=funcionalidades" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300">
              Habla con nosotros
            </a>

          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppMassOperations;
