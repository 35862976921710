import { Clock, Star, PiggyBank, Zap } from 'lucide-react';

const VentasSection: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="flex flex-col lg:flex-row gap-12">
        {/* Sección de Beneficios clave */}
        <section className="flex-1 order-2 lg:order-1">
          <h3 className="text-2xl font-light mb-6">Beneficios clave</h3>
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col items-center">
              <Clock className="w-12 h-12 mb-2 text-green-500" /> {/* Icono en verde */}
              <span className="text-sm">Atención 24/7</span>
            </div>
            <div className="flex flex-col items-center">
              <Star className="w-12 h-12 mb-2 text-green-500" /> {/* Icono en verde */}
              <span className="text-sm">Experiencia excepcional</span>
            </div>
            <div className="flex flex-col items-center">
              <PiggyBank className="w-12 h-12 mb-2 text-green-500" /> {/* Icono en verde */}
              <span className="text-sm">Ahorro de costes</span>
            </div>
            <div className="flex flex-col items-center">
              <Zap className="w-12 h-12 mb-2 text-green-500" /> {/* Icono en verde */}
              <span className="text-sm">Automatización</span>
            </div>
          </div>
        </section>

        {/* Sección de Atención al Cliente */}
        <section className="flex-1 order-1 lg:order-2">
          <h2 className="text-2xl font-semibold mb-4">Atención al cliente</h2>
          <p className="text-lg mb-6">
            Tus clientes quieren atención 24/7 y, si es en sus canales preferidos, mucho mejor. Pero no solo eso, también quieren una experiencia excepcional…
          </p>
          <p className="text-lg mb-6">
            ¿Y si pudieras darles lo que piden de forma automática o, en los casos más complicados, darles asistencia personalizada?
          </p>
          <p className="text-lg mb-6">
            Enamora a tus clientes con un servicio de atención al cliente de 5 estrellas que, además, podrás automatizar ahorrando hasta el 80% de tus costes.
          </p>
          <p className="text-lg font-semibold">Ahorra cientos de horas</p>
        </section>
      </div>
    </div>
  );
};

export default VentasSection;
