import React from 'react';

const comparisonData = [
  { 
    channel: 'Email', 
    recipients: '100.000', 
    saasCost: '$299', 
    messageCost: '$0', 
    investment: '$299', 
    opens: '15.000', 
    clicks: '300', 
    sales: '18', 
    averageTicket: '$50', 
    netSales: '$900', 
    roi: '2.01',
    logo: './email.png'  // Agregado logo
  },
  { 
    channel: 'WhatsApp', 
    recipients: '100.000', 
    saasCost: '$399', 
    messageCost: '$0,05', 
    investment: '$5,399', 
    opens: '95.000', 
    clicks: '57.000', 
    sales: '3.420', 
    averageTicket: '$50', 
    netSales: '$171.000', 
    roi: '$30,67',
    logo: './WhatsApp.svg.png'  // Agregado logo
  }
];

const WhatsAppVsEmail: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-12"><span style={{ fontWeight: 'bold' }}>WhatsApp</span> vs Email</h2>
        
        <div className="flex justify-center space-x-8 mb-12">
          <img src="/112.png" alt="Comparison Image 1" width={300} height={200} className="rounded-lg" />
          <img src="/113.png" alt="Comparison Image 2" width={300} height={200} className="rounded-lg" />
          <img src="/114.png" alt="Comparison Image 3" width={300} height={200} className="rounded-lg" />
        </div>
        
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-green-100">
                <th className="border border-gray-300 p-2">CANAL</th>
                <th className="border border-gray-300 p-2">NÚMERO DE DESTINATARIOS</th>
                <th className="border border-gray-300 p-2">COSTO POR SAAS</th>
                <th className="border border-gray-300 p-2">COSTO POR MENSAJE</th>
                <th className="border border-gray-300 p-2">INVERSIÓN</th>
                <th className="border border-gray-300 p-2">APERTURAS</th>
                <th className="border border-gray-300 p-2">CLICS</th>
                <th className="border border-gray-300 p-2">VENTAS</th>
                <th className="border border-gray-300 p-2">TICKET PROMEDIO</th>
                <th className="border border-gray-300 p-2">VENTAS NETAS</th>
                <th className="border border-gray-300 p-2">ROI</th>
              </tr>
            </thead>
            <tbody>
              {comparisonData.map((row, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="border border-gray-300 p-2 flex items-center">
                    <img src={row.logo} alt={`${row.channel} logo`} width={24} height={24} className="mr-2" />
                    {row.channel}
                  </td>
                  <td className="border border-gray-300 p-2">{row.recipients}</td>
                  <td className="border border-gray-300 p-2">{row.saasCost}</td>
                  <td className="border border-gray-300 p-2">{row.messageCost}</td>
                  <td className="border border-gray-300 p-2">{row.investment}</td>
                  <td className="border border-gray-300 p-2">{row.opens}</td>
                  <td className="border border-gray-300 p-2">{row.clicks}</td>
                  <td className="border border-gray-300 p-2">{row.sales}</td>
                  <td className="border border-gray-300 p-2">{row.averageTicket}</td>
                  <td className="border border-gray-300 p-2">{row.netSales}</td>
                  <td className="border border-gray-300 p-2">{row.roi}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppVsEmail;
