import React, { useState } from 'react';

type FAQItem = {
  question: string;
  answer: string;
};

const faqs: FAQItem[] = [
  {
    question: "¿Qué es una Base de Datos?",
    answer: "En el contexto de aplicaciones que utilizan WhatsApp, una base de datos puede ser esencial para gestionar y almacenar información de manera eficiente. La base de datos permite mantener un registro de contactos, mensajes y actividades relacionadas con los usuarios."
  },
  {
    question: "¿Cómo funciona el precio en Bot de WhatsApp?",
    answer: "De la forma más sencilla, es un coste fijo. Tendrás un coste fijo que es la suscripción no pagarás ningún extra por todos nuestros servicios de la plataforma."
  },
  {
    question: "¿Cuánto voy a pagar si me suscribo hoy?",
    answer: "Es muy sencillo, pagarás la parte proporcional del mes de la suscripción."
  },
  {
    question: "¿Puedo utilizar mi número de WhatsApp?",
    answer: "Si claro, aunque recomendamos que tengas uno diferente para asi evitar cualquier cosa en actualizacion es con WhatsApp."
  },
  {
    question: "¿Qué pasa una vez que me suscribo a Bot de WhatsApp?",
    answer: "Tendrás una sesión de instalación para todos nuestros clientes, el objetivo de esto es dejar funcionando tu chatbot y ayudarte a sortear cualquier dificultad que puedas tener conectando WhatsApp. Te acompañaremos en el proceso."
  },
  {
    question: "¿Cómo podré realizar ChatBots en WhatsApp, y Web?",
    answer: "Tienes a tu disposición más de 30 plantillas de WhatsApp para WhatsApp, y web, con los planes Estandar y Premium tendrás acceso a un plan de acompañamiento. Además todos nuestros clientes tienen acceso a nuestro equipo de soporte y success via WhatsApp e Email."
  },
  {
    question: "¿Cuándo puedo cancelar mi cuenta o cambiar de plan?",
    answer: "Puedes cancelar tu cuenta o cambiar de plan al finalizar el mes."
  },
  {
    question: "¿Cuáles son los costes asociados a las conversaciones de WhatsApp?",
    answer: "El costo por mensajes en Bot de WhatsApp y envios de mensajes masivos es de 0."
  }
];

const FAQItem: React.FC<{ item: FAQItem }> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-lg">{item.question}</span>
        <span className="text-2xl">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{item.answer}</p>}
    </div>
  );
};

export default function FAQSection() {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-light text-gray-900 text-center mb-8">
        <span style={{ fontWeight: 'bold' }}>Preguntas</span> frecuentes
        </h2>
        <div className="bg-white rounded-lg shadow-sm">
          {faqs.map((faq, index) => (
            <FAQItem key={index} item={faq} />
          ))}
        </div>
      </div>
    </div>
  );
}