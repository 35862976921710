import React from 'react';
import SubscriptionPlans from '../componentes/SuscriptionPlans';
import FeaturesList from '../componentes/FeaturesList';
import FeatureCheck from '../componentes/FeatureCheck'
import FeatureComparison from '../componentes/FeatureComparison';
import PlanCalculator from '../componentes/PlanCalculator';
import ClientsSection from '../componentes/ClientsSection';
import ContactExperts from '../componentes/ContactExperts';
import FAQSection from '../componentes/FaqSection';
import ConversationalProjectSection from '../componentes/ConversationalProjectSection';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const Planes: React.FC = () => {
  return (
    <div>
      <SubscriptionPlans />
      <FeaturesList />
      <FeatureCheck />
      <FeatureComparison />
      <PlanCalculator />
      <ClientsSection />
      <ContactExperts />
      <FAQSection />
      <ConversationalProjectSection />
      <WhatsAppChatbot />
    </div>
  );
};

export default Planes;
