import React from 'react';
import HeroSoluciones from '../componentes/HeroSoluciones';
import ClientsSection from '../componentes/ClientsSection';
import MarketingSection from '../componentes/MarketingSection';
import VentasSection from '../componentes/VentasSection'
import ConversacionSection from '../componentes/CoversacionSection';
import WhyTrustUsSection from '../componentes/WhyTrustUsSection';
import ProjectsSection from '../componentes/ProjectsSection';
import ContactForm from '../componentes/ContactForm';
import WhatsAppChatbot from '../componentes/WhatsAppChatbot';

const Soluciones: React.FC = () => {
  const heroTexts = ['SOPORTE', 'ECOMMERCE', 'MARKETING Y VENTAS'];
  const typingSpeed = 3000; // Cambia de texto cada 3 segundos

  return (
    <div>
      <HeroSoluciones heroTexts={heroTexts} typingSpeed={typingSpeed} />
      <ClientsSection /> {/* Sección de clientes añadida aquí */}
      {/* Aquí puedes añadir más contenido específico para "Soluciones" */}
      <MarketingSection />
      <VentasSection />
      <ConversacionSection />
      <WhyTrustUsSection />
      <ProjectsSection />
      <ContactForm />
      <WhatsAppChatbot />
    </div>
  );
};

export default Soluciones;
