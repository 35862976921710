import { useEffect } from 'react';
import '../App.css';

const CustomerServiceSection = () => {
    // Mover useEffect aquí, en la parte superior del componente
    useEffect(() => {
      console.log("CustomerServiceSection se está renderizando");
    }, []);
  
    return (
      <section className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8">Atención al Cliente</h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 md:pr-8 order-2 md:order-1">
              <p className="mb-4">
                Tus clientes quieren atención 24/7 y, si es en sus canales preferidos, mucho mejor. Pero no solo eso, también quieren una experiencia excepcional…
              </p>
              <p className="mb-4">
                ¿Y si pudieras darles lo que piden de forma automática o, en los casos más complicados, darles asistencia personalizada?
              </p>
              <p className="mb-4">
                Enamora a tus clientes con un servicio de atención al cliente de 5 estrellas que, además, podrás automatizar ahorrando hasta el 80% de tus costes.
              </p>
              <p className="text-xl font-semibold">
                Ahorra cientos de horas
              </p>
            </div>
            <div className="md:w-1/2 mb-8 md:mb-0 order-1 md:order-2">
              <img src="./102.png?height=300&width=500" alt="Atención al Cliente" width={500} height={300} />
            </div>
          </div>
        </div>
      </section>
    );
  };

export default CustomerServiceSection;