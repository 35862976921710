import { MessageSquare, ShoppingCart, BarChart, Users } from 'lucide-react';

const MarketingSection: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      {/* Título y descripción */}
      <section className="mb-16 text-center">
        <h2 className="font-light text-3xl">
          Desarrollamos la <span style={{ fontWeight: 'bold' }}>solución conversacional</span> que necesitas
        </h2>
        <p className="text-lg max-w-3xl mx-auto font-light font-gray-500">
          Nuestros expertos conversacionales te ayudarán a diseñar la solución que tu empresa necesita.
        </p>
      </section>

      {/* Secciones de marketing y características */}
      <div className="flex flex-col lg:flex-row gap-12">
        {/* Sección de Marketing y ventas */}
        <section className="flex-1">
          <h3 className="text-2xl font-semibold mb-4">Marketing y ventas</h3>
          <p className="text-lg mb-6">
            Aumenta tus ventas gracias a implementar estrategias conversacionales en WhatsApp.
            En Bot de WhatsApp, hemos comprobado una y otra vez cómo nuestros clientes han obtenido resultados increíbles al comunicarse con sus clientes en los canales que ellos prefieren.
          </p>
          <p className="text-lg mb-6">
            Contamos con una amplia experiencia en diversas industrias, y hemos implementado con éxito decenas de estrategias de marketing y ventas utilizando nuestras soluciones.
          </p>
          <p className="text-lg font-semibold">
            ¿Estás listo para llevar tus ventas al siguiente nivel?
          </p>
        </section>

        {/* Sección de Características más utilizadas */}
        <section className="flex-1">
          <h3 className="text-2xl font-light mb-6">Características más utilizadas</h3>
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col items-center text-center">
              <MessageSquare className="w-12 h-12 mb-2 text-green-500" /> {/* Cambiado a verde */}
              <span className="text-sm">Mensajería</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <ShoppingCart className="w-12 h-12 mb-2 text-green-500" /> {/* Cambiado a verde */}
              <span className="text-sm">Ventas</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <BarChart className="w-12 h-12 mb-2 text-green-500" /> {/* Cambiado a verde */}
              <span className="text-sm">Análisis</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <Users className="w-12 h-12 mb-2 text-green-500" /> {/* Cambiado a verde */}
              <span className="text-sm">Clientes</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MarketingSection;
