import React from 'react';
import Slider from 'react-slick';
import '../App.css';

// Imágenes de los clientes
const clientImages = [
  "./500.png",
  "./501.png",
  "./502.png",
  "./503.png",
  "./504.png",
  "./505.png",
  "./506.png",
  "./507.png",
  "./508.png",
  "./509.png",
  "./510.png",
  "./511.png",
];

const ClientsSection = () => {
  // Configuración del slider
  const settings = {
    dots: true, // Mostrar puntos de navegación
    infinite: true, // Carrusel infinito
    speed: 500, // Velocidad de la transición
    slidesToShow: 6, // Mostrar 6 slides al mismo tiempo
    slidesToScroll: 1, // Desplazar 1 slide por vez
    autoplay: true, // Habilitar el desplazamiento automático
    autoplaySpeed: 3000, // Tiempo entre desplazamientos (3 segundos)
    pauseOnHover: true, // Pausar cuando el mouse esté encima
    responsive: [
      {
        breakpoint: 1024, // Pantallas medianas
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Pantallas pequeñas (tablets y móviles)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Pantallas muy pequeñas (móviles)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8 w-full">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-light text-center mb-8">Clientes destacados</h2>
        <Slider {...settings}>
          {clientImages.map((imageUrl, index) => (
            <div key={index} className="flex items-center justify-center">
              <img 
                src={imageUrl} 
                alt={`Client ${index + 1}`} 
                className="client-logo" // Clase para aplicar el efecto
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ClientsSection;
