'use client'

import { useState, useEffect } from 'react'

export default function Component() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const slides = [
    {
      title: "ChatGPT para marketing y ventas",
      content: "Aumenta la eficiencia de tus estrategias de marketing y ventas con ChatGPT: Genera y califica leads de calidad, personaliza tus mensajes promocionales y cierra ventas de manera más efectiva, todo sin salir de WhatsApp.",
      bullets: [
        "Cualifica tus clientes potenciales de forma automática",
        "Modifica el flujo de la conversación en base a las elecciones de tus clientes",
        "Resume la conversación",
        "Consulta el estado de la conversación y si es un buen momento para cerrar la venta",
        "Ofrece sugerencias de respuesta y siguientes pasos para una conversación"
      ],
      image: "/100.jpg" // Cambia esta ruta por la imagen correspondiente
    },
    {
      title: "ChatGPT para la atención al cliente",
      content: "Ayuda a tus clientes con ChatGPT: Respuestas inmediatas, personalizadas y precisas para mejorar la experiencia y fidelización del cliente",
      bullets: [
        "Soporte inmediato en WhatsApp a través de chatbots",
        "Disponible 24 horas",
        "Utiliza tu propia base de datos de conocimiento",
        "Define el comportamiento para tu chatbot",
        "Integrado con tus herramientas de gestión de tickets",
        "Asistencia en tiempo real a tus agentes",
      ],
      image: "/102.jpg" // Cambia esta ruta por la imagen correspondiente
    },
    {
      title: "ChatGPT para eCommerce",
      content: "Aumenta la eficiencia de tus estrategias de marketing y ventas con ChatGPT: Genera y califica leads de calidad, personaliza tus mensajes promocionales y cierra ventas de manera más efectiva, todo sin salir de WhatsApp.",
      bullets: [
        "Ofrece sugerencias a medida de tu catálogo de productos",
        "Integrado con tu tienda online en WhatsApp",
        "Responde a las dudas de tus clientes en tiempo real"
      ],
      image: "/103.jpg" // Cambia esta ruta por la imagen correspondiente
    },
    {
      title: "ChatGPT para la comunicación interna",
      content: "Aumenta la eficiencia de tus estrategias de marketing y ventas con ChatGPT: Genera y califica leads de calidad, personaliza tus mensajes promocionales y cierra ventas de manera más efectiva, todo sin salir de WhatsApp.",
      bullets: [
        "Crea asistentes con el conocimiento interno de tu empresa que ayuden a tus empleados",
        "Utiliza la inteligencia artificial y WhatsApp para distribuir planes de formación a medida",
        "Integrado con tus herramientas de RRHH",
        "Convierte WhatsApp en el asistente personal de tus empleados, con acceso a sus agendas y tareas"
      ],
      image: "/105.jpg" // Cambia esta ruta por la imagen correspondiente
    }
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    }, 7000)

    return () => clearInterval(timer)
  }, [])

  return (
    <section className="py-12 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-light text-center mb-6">Qué puedes conseguir al usar <span style={{ fontWeight: 'bold' }}>ChatGPT en WhatsApp</span></h2>
        <p className="text-center mb-12 max-w-3xl mx-auto">
          Cada conversación cuenta. Impulsa la satisfacción del cliente y la eficiencia operativa combinando ChatGPT con WhatsApp. Obtén comunicaciones únicas, respuestas instantáneas y asistencia para tus agentes, todo en una sola integración. Obtendrás más ventas, más rápido y reducirás tus costes.
        </p>
        
        <div className="border rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 bg-muted flex items-center justify-center">
              <img
                src={slides[currentSlide].image} // Usa la imagen correspondiente para cada diapositiva
                alt={slides[currentSlide].title}
                width={500}
                height={250}
                className="max-w-xs h-auto object-cover"
              />
            </div>
            <div className="md:w-1/2 p-6">
              <h3 className="text-2xl font-semibold mb-4">{slides[currentSlide].title}</h3>
              <p className="mb-4">{slides[currentSlide].content}</p>
              <ul className="list-disc pl-5 space-y-2">
                {slides[currentSlide].bullets.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
