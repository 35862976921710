import React from 'react';

const WhatsAppAIResponses: React.FC = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="/115.png"
              alt="WhatsApp AI Responses"
              width={600}
              height={400}
              className="rounded-lg"
            />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-light mb-6">
              Responde con IA tus mensajes Masivos de <span style={{ fontWeight: 'bold' }}>WhatsApp</span>
            </h2>
            <p className="text-lg mb-6">
              Una vez realizado el envío de mensajes Masivos, puedes dirigir a tus clientes a tu website o cualquier dirección web.
              Sin embargo, también puedes terminar tu interacción con el cliente vía WhatsApp, para ello podrás utilizar el Bot de WhatsApp donde tus agentes podrán atender todo tipo de consultas que se lleven a cabo después de este envío masivo.
            </p>
            <p className="text-lg mb-8">
              Sin embargo, si quieres que esto sea una acción escalable, podrás activar chatbots sencillos con nuestras más de 100 plantillas prediseñadas, o incluso podrás activar Chatbots con ChatGpt.
            </p>
            <a href="https://wa.me/573161392317?text=funcionalidades" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300">
              Habla con nosotros
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatsAppAIResponses;
