import React from 'react';

const partnerTypes = [
  { percentage: "10%", type: "Partner de Servicios" },
  { percentage: "15%", type: "Country Partner" }
];

export default function PartnerPercentages() {
  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-light text-gray-900 text-center mb-12">
          Cuánto reciben los <span style={{ fontWeight: 'bold' }}>partners</span> de los pagos de los clientes
        </h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          {partnerTypes.map((partner, index) => (
            <div key={index} className="text-center">
              <div className="text-6xl font-bold text-green-600 mb-2"> {/* Cambiado a verde */}
                {partner.percentage}
              </div>
              <div className="text-xl text-gray-700 mb-2">
                {partner.type}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full h-px bg-gray-300 my-8"></div> {/* Línea divisoria */}
      </div>
    </div>
  );
}
