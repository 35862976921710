import { MessageCircleMore, Brain, UsersRound, BadgeAlert, Link } from 'lucide-react';
import '../App.css';


const IconSection2 = () => (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <div className="flex flex-col items-center text-center">
            <Brain className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Integración con IA ChatGPT</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <MessageCircleMore className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Chatbots para Atención al Cliente</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <UsersRound className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Tu equipo contestando el mismo WhatsApp</h4>
          </div>
          <div className="flex flex-col items-center text-center">
            <BadgeAlert className="w-12 h-12 text-green-500 mb-2" />
            <h4 className="text-lg font-semibold">Integración con herramientas de soporte</h4>
          </div>
        </div>
      </div>
    </section>
  );

export default IconSection2;