import { ShoppingCart, MessageCircle, CreditCard, TrendingUp } from 'lucide-react';

const ConversacionSection: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="flex flex-col lg:flex-row gap-12">
        {/* Sección principal */}
        <section className="flex-1">
          <h2 className="text-2xl font-semibold mb-4">Conversational Commerce</h2>
          <h3 className="text-2xl font-light mb-4">Aumenta las ventas de tu e-commerce con WhatsApp</h3>
          <p className="text-lg mb-6">
            Añade el canal más eficaz a tu estrategia de venta online. Potencia tu marketing, servicio al cliente y ventas conBot de WhatsApp.
          </p>
          <h4 className="text-xl font-semibold mb-4">Recibe pedidos y pagos a través de WhatsApp</h4>
          <p className="text-lg mb-6">
            Tengas o no un eCommerce, si quieres vender productos a través de canales como WhatsApp, sabemos cómo hacerlo realidad.
          </p>
          <p className="text-xl font-bold">¡Vende más en tu e-commerce!</p>
        </section>

        {/* Sección de íconos */}
        <section className="flex-1 flex items-center justify-center">
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col items-center text-center">
              <ShoppingCart className="w-16 h-16 mb-2 text-green-500" />
              <span className="text-sm">Ventas en línea</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <MessageCircle className="w-16 h-16 mb-2 text-green-500" />
              <span className="text-sm">Comunicación efectiva</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <CreditCard className="w-16 h-16 mb-2 text-green-500" />
              <span className="text-sm">Pagos por WhatsApp</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <TrendingUp className="w-16 h-16 mb-2 text-green-500" />
              <span className="text-sm">Aumento de ventas</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ConversacionSection;
