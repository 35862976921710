export default function WhatsAppChatGPTComponent() {
  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <h2 className="text-3xl font-light mb-4">WhatsApp y GPT de <span style={{ fontWeight: 'bold' }}>ChatGPT</span></h2>
            <h3 className="text-xl font-semibold mb-4">Crea tus GPTs y conéctalos a WhatsApp</h3>
            <p className="text-base text-muted-foreground">
              Los GPT (Generative Pre-trained Transformers) de ChatGPT te permiten personalizar el uso de ChatGPT para lograr fines específicos basados en la información que puedas compartir con este modelo de AI. Podrás entrenar este modelo con FAQs, PDFs, además de darle instrucciones muy específicas así como personalizar el tono de respuesta y más.
            </p>
            <p className="text-base text-muted-foreground mt-4">
              Con Bot de WhatsApp podrás conectar tu propio GPT de ChatGPT a WhatsApp y Web para resolver cualquier tipo de consultas de tus clientes o leads, no solamente basado en información general sino en las propias instrucciones de configuración del modelo. Podrás desbloquear un mundo de posibilidades para tu negocio.
            </p>
          </div>
          <div className="md:w-1/2">
            <img
              src="101.jpg"
              alt="WhatsApp y GPT de ChatGPT"
              width={600}
              height={400}
              className="w-full h-auto rounded-lg" // Se eliminó 'shadow-lg'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
