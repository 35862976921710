import { useState, useEffect } from 'react';

const SubscriptionPlans = () => {
  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const [currency, setCurrency] = useState<'USD' | 'COP'>('USD');
  const [usdToCopRate, setUsdToCopRate] = useState<number>(0);

  const plans = [
    {
      name: "Básico",
      price: 12,
      annualPrice: 115,
      features: [
        "Base de Datos para 5 mil clientes.",
        "Envio de mensajes ilimitados a los clientes.",
        "1 Chat bot principal y 2 para ventas."
      ],
      cta: {
        text: "Empieza Ahora Básico",
        link: "https://wa.me/573161392317?text=basico" // Reemplaza con tu enlace
      },
      popular: false
    },
    {
      name: "Estándar",
      price: 30,
      annualPrice: 288,
      features: [
        "Base de Datos para 50 mil clientes.",
        "Envio de mensajes ilimitados a los clientes.",
        "2 Chat bot principal y 10 para ventas.",
        "Consulta de los clientes de la base de datos desde el WhatsApp."
      ],
      cta: {
        text: "Empieza Ahora Estándar",
        link: "https://wa.me/573161392317?text=estandar" // Reemplaza con tu enlace
      },
      popular: false
    },
    {
      name: "Premium",
      price: 80,
      annualPrice: 768,
      features: [
        "Base de Datos de clientes ilimitados.",
        "Envio de mensajes ilimitados a los clientes.",
        "3 Chat bot principal y 30 para ventas.",
        "1 Chat Bot para tu pagina web.",
        "Consulta de los clientes de la base de datos desde el WhatsApp.",
      ],
      cta: {
        text: "Empieza Ahora Premium",
        link: "https://wa.me/573161392317?text=premium" // Reemplaza con tu enlace
      },
      popular: true
    }
  ];

  const formatPrice = (price: number): string => {
    const roundedPrice = Math.round(price);
    if (currency === 'USD') {
      return `$${roundedPrice}`;
    } else {
      return `COP ${Math.round(roundedPrice * usdToCopRate).toLocaleString('es-CO')}`;
    }
  };

  const fetchExchangeRate = async () => {
    const url = `https://api.exchangerate-api.com/v4/latest/USD`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rate = data.rates.COP;
      setUsdToCopRate(rate);
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-light text-center mb-8">Empieza <span style={{ fontWeight: 'bold' }}>Básico</span> y crece con nosotros</h2>

      <div className="flex justify-center items-center space-x-4 mb-8">
        <button 
          className={`px-4 py-2 rounded-full ${isAnnual ? "border border-gray-300" : "bg-green-500 text-white"}`}
          onClick={() => setIsAnnual(false)}
        >
          Plan mensual
        </button>
        
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
              className="hidden"
            />
            <div className={`toggle w-14 h-8 rounded-full ${isAnnual ? 'bg-blue-500' : 'bg-gray-300'} flex items-center p-1`}>
              <div className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform ${isAnnual ? 'translate-x-6' : 'translate-x-0'}`}></div>
            </div>
          </div>
        </label>

        <button 
          className={`px-4 py-2 rounded-full ${isAnnual ? "bg-green-500 text-white" : "border border-gray-300"}`}
          onClick={() => setIsAnnual(true)}
        >
          Plan anual - Ahorra hasta 20%
        </button>
      </div>

      <div className="flex justify-center items-center space-x-4 mb-8">
        <button 
          className={`flex items-center px-4 py-2 rounded-full ${currency === 'USD' ? "bg-blue-500 text-white" : "border border-gray-300"}`}
          onClick={() => setCurrency('USD')}
        >
          <img src="/usa.png" alt="USD" className="w-6 h-6 rounded-full mr-2" />
          USD
        </button>
        <button 
          className={`flex items-center px-4 py-2 rounded-full ${currency === 'COP' ? "bg-blue-500 text-white" : "border border-gray-300"}`}
          onClick={() => setCurrency('COP')}
        >
          <img src="/colombia.png" alt="COP" className="w-6 h-6 rounded-full mr-2" />
          COP
        </button>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className={`bg-gray-100 rounded-lg p-4 flex flex-col shadow-lg transition-transform duration-300 transform hover:scale-105 relative`}>
            {plan.popular && (
              <div className="absolute bg-green-500 text-white text-sm font-medium py-1 px-2 transform -translate-y-1/2 right-4 top-4 rounded-md">
                Más popular
              </div>
            )}
            <h3 className="text-xl font-bold">{plan.name}</h3>
            <p className="text-3xl font-bold">
              {formatPrice(isAnnual ? (plan.annualPrice || plan.price * 12) : plan.price)}
              {plan.price > 0 && ` / ${isAnnual ? 'año' : 'mes'}`}
            </p>
            <div className="flex-grow">
              <ul className="space-y-2">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center">
                    <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <a href={plan.cta.link} target="_blank" rel="noopener noreferrer" className="mt-4 w-full bg-green-500 text-white py-2 rounded-full text-center">
              {plan.cta.text}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
